import React, {
  useContext,
  useCallback,
  useMemo,
  useEffect,
  useReducer,
  useState,
  useRef,
} from "react";
import {
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  ActivityIndicator,
} from "react-native";
import { useHistory } from "react-router-dom";
import StyledInput from "../../StyledInput";
import PhoneInput from "../../PhoneInput/index";
import StyledPicker from "../../StyledPicker";
import CheckBox from "../../CheckBox";
import palette from "../../../styles/palette";
import Modal from "../../../modals/Modal";
import Row from "../../../layouts/Row";
import Col from "../../../layouts/Col";

import ContactContext from "../../../contexts/ContactContext";
import CustomersContext from "../../../contexts/CustomersContext";
import UserContext from "../../../contexts/UserContext";
import useToggle from "../../../hooks/useToggle";
import { ACCOUNT_TYPES } from "../../../constants/accountTypes";
import commonStyles from "~/styles/common";
import {
  checkCustomerFormValid,
  filterCustomerForEvent,
  getFullAddress,
} from "../../../helpers/customers";
import TeamContext from "~/contexts/TeamContext";
import ThreadsContext from "../../../contexts/ThreadsContext";
import { parsePhoneNumber } from "libphonenumber-js";
import FieldPulse from "../../../services/fieldpulse";
import Heading from "../../Heading/index.web";
import {
  fieldRequired,
  getFullName,
  getMultilineNumberOfLines,
  minLen,
  validEmail,
  validPhone,
} from "../../../helpers/text";
import IconButton from "../../Buttons/IconButton";
import SolidButton from "../../Buttons/SolidButton";
import { JOB_STATUS } from "../../../constants/jobs";
import { INVOICE_STATUS } from "../../../constants/invoices";
import { getLabelForStatus as getLabelForStatusEstimate } from "../../../helpers/estimates";
import { getLabelForStatus as getLabelForStatusInvoice } from "../../../helpers/invoices";
import { getLabelForStatus as getLabelForStatusJob } from "../../../helpers/jobs";
import { faBuilding, faUser } from "@fortawesome/free-regular-svg-icons";
import AddressInput from "../../AddressInput/index.web";
import ConversationContext from "../../../contexts/ConversationContext";
import { WebAppPostMessage } from "../../../models/WebAppPostMessage";
import {
  CREATE_COMMAND,
  ESTIMATE_OBJECT,
  INVOICE_OBJECT,
  JOB_OBJECT,
  OPEN_COMMAND,
} from "../../../constants/webapp-integration";
import { faCirclePlus } from "@fortawesome/pro-regular-svg-icons";
import { OPTIONS } from "../../../constants/customer";

const validators = {
  first_name: (val) => fieldRequired("First Name", val),
  phone: (val) =>
    fieldRequired("Phone", val) ||
    minLen("Phone", val, 10) ||
    validPhone("Phone", val),
  email: (val) => validEmail("Email", val),
  alt_email: (val) => validEmail("Alternate Email", val),
  alt_phone: (val) =>
    minLen("Alternate Phone", val, 10) || validPhone("Alternate Phone", val),
};

const validationErrorReducer = (state, action) => {
  if (action) {
    return {
      ...state,
      ...action,
    };
  }

  return state;
};

const CustomerForm = ({
  type,
  onUpdate = null,
  participantId = "",
  customerId = "",
  modal = true,
  showContact = true,
}) => {
  const history = useHistory();
  const {
    user: {
      account: { country },
    },
    company,
  } = useContext(UserContext);
  const { conversation } = useContext(ConversationContext);

  const {
    contact,
    error,
    updateContact,
    saving,
    saveContact,
    createContact,
    resetBillAddress,
    dirty,
    setHasErrors,
    loading,
  } = useContext(ContactContext);
  const { members } = useContext(TeamContext);
  // Define UI states
  const [useBillAddress, setUseBillAddress] = useToggle(
    contact?.billing_address_1 || false,
  );
  const [isAddressExpanded, setIsAddressExpanded] = useToggle();
  const [isBillAddrExpanded, setIsBillAddrExpanded] = useToggle();
  const [loadingLeadSources, setLoadingLeadSources] = useState(false);
  const [leadSources, setLeadSources] = useState([]);

  const { quickbooks_enabled, xero_enabled } = company;

  useEffect(() => {
    const getLeadSources = async () => {
      setLoadingLeadSources(true);
      try {
        const res = await FieldPulse.getLeadSources();
        if (!res.error) {
          setLeadSources(res?.response || []);
        }
      } catch (e) {
        console.warn(e);
      }
      setLoadingLeadSources(false);
    };
    getLeadSources();
  }, []);

  useEffect(() => {
    if (!contact.account_type) {
      // Set "individual" as the default
      updateContact({ account_type: ACCOUNT_TYPES[0].value });
    } else {
      if (contact?.account_type === ACCOUNT_TYPES[0].value) {
        const firstName = contact.first_name;
        const lastName = contact.last_name;
        const display_name = getFullName(firstName, lastName);
        updateContact({
          display_name,
          first_name: firstName,
          last_name: lastName,
        });
      } else {
        const companyName = contact.company_name;
        updateContact({
          company_name: companyName,
          display_name: companyName,
        });
      }
    }
  }, [contact?.account_type]);

  const [validationErrors, setValidationError] = useReducer(
    validationErrorReducer,
    {},
  );

  const hasErrors = useMemo(
    () =>
      Object.keys(validationErrors).reduce(
        (result, attr) => result || !!validationErrors[attr],
        false,
      ),
    [validationErrors],
  );

  useEffect(() => {
    setHasErrors(!!hasErrors);
  }, [hasErrors]);

  const teamMembers = useMemo(() => {
    return [
      { name: "Unassigned", value: "Unassigned" },
      ...members
        ?.filter((user) => user?.is_active)
        ?.map((teamMember) => {
          return {
            name: `${teamMember.first_name} ${teamMember.last_name}`,
            value: teamMember.id,
          };
        }),
    ];
  }, [members]);

  const onChange = useCallback(
    (attribute) => (val) => {
      updateContact({ [attribute]: val });

      if (
        (attribute === "first_name" || attribute === "last_name") &&
        contact?.account_type === ACCOUNT_TYPES[0].value &&
        !quickbooks_enabled &&
        !xero_enabled
      ) {
        const firstName = attribute === "first_name" ? val : contact.first_name;
        const lastName = attribute === "last_name" ? val : contact.last_name;
        const display_name = getFullName(firstName, lastName);
        updateContact({
          display_name,
          first_name: firstName,
          last_name: lastName,
        });
      }

      if (
        attribute === "company_name" &&
        !quickbooks_enabled &&
        !xero_enabled
      ) {
        updateContact({
          company_name: val,
          display_name: val,
        });
      }

      if (validators[attribute]) {
        const hasError = validators[attribute](val);
        setValidationError(
          hasError ? { [attribute]: hasError } : { [attribute]: undefined },
        );
      }
    },
    [updateContact, setValidationError, quickbooks_enabled, xero_enabled],
  );

  const onSave = useCallback(async () => {
    const res = await saveContact(participantId);

    if (onUpdate && res.response && !res.error) {
      await onUpdate(res.response);
    }

    if (!res.error) {
      history.goBack();
    }
  }, [saveContact, history, participantId, onUpdate]);

  const onCreate = useCallback(async () => {
    const res = await createContact();

    if (onUpdate && !res.error) {
      await onUpdate(res?.response);
    }

    if (!res.error) {
      history.goBack();
    }
  }, [createContact, history]);

  const address = useMemo(() => {
    return getFullAddress(contact);
  }, [contact]);

  const billingAddress = useMemo(() => {
    return getFullAddress(contact, "billing");
  }, [contact]);

  useEffect(() => {
    try {
      if (
        !customerId &&
        !contact?.remote_id &&
        !contact?.lead_source_id &&
        conversation?.twilio_number?.lead_source_name &&
        conversation?.participant?.id
      ) {
        updateContact({
          lead_source: conversation?.twilio_number?.lead_source_name,
          lead_source_id: conversation?.twilio_number?.lead_source_id,
        });
      }
    } catch {}
  }, [conversation?.id, customerId, contact?.remote_id]);

  useEffect(() => {
    if (customerId && contact?.main_location) {
      updateContact({
        address_1: contact?.main_location?.address_1 || "",
        address_2: contact?.main_location?.address_2 || "",
        city: contact?.main_location?.city || "",
        state: contact?.main_location?.state || "",
        zip_code: contact?.main_location?.zip_code || "",
      });
    }
  }, [customerId, contact?.main_location?.id, contact?.remote_id]);

  const Form = (
    <View style={[styles.customer_create_modal_container]}>
      <View>
        {error && (
          <View style={[styles.error_message_container]}>
            <Text style={[styles.error_message_text]}>{error}</Text>
          </View>
        )}

        {validationErrors.email && (
          <View style={[styles.error_message_container]}>
            <Text style={[styles.error_message_text]}>
              {validationErrors.email}
            </Text>
          </View>
        )}

        {validationErrors.alt_email && (
          <View style={[styles.error_message_container]}>
            <Text style={[styles.error_message_text]}>
              {validationErrors.alt_email}
            </Text>
          </View>
        )}

        {validationErrors.first_name && (
          <View style={[styles.error_message_container]}>
            <Text style={[styles.error_message_text]}>
              {validationErrors.first_name}
            </Text>
          </View>
        )}

        {!!contact?.phone && validationErrors.phone && (
          <View style={[styles.error_message_container]}>
            <Text style={[styles.error_message_text]}>
              {validationErrors.phone}
            </Text>
          </View>
        )}

        {!!contact?.alt_phone &&
          contact.alt_phone !== "+1" &&
          validationErrors.alt_phone && (
            <View style={[styles.error_message_container]}>
              <Text style={[styles.error_message_text]}>
                {validationErrors.alt_phone}
              </Text>
            </View>
          )}
      </View>
      <View style={{ flexGrow: 2 }}>
        <View style={[commonStyles.row]}>
          {contact.account_type === ACCOUNT_TYPES[1].value && (
            <Field label="Company Name" required>
              <StyledInput
                onChangeText={onChange("company_name")}
                defaultValue={contact?.company_name || ""}
                lessPadding
                bold
                autoComplete="none"
                nativeID="company_name"
              />
            </Field>
          )}
        </View>
        {(xero_enabled || quickbooks_enabled) && (
          <View style={[commonStyles.row]}>
            <Field
              label="Display Name"
              style={[commonStyles.mr4, { flex: 1 }]}
              required
            >
              <StyledInput
                onChangeText={onChange("display_name")}
                defaultValue={contact?.display_name || ""}
                lessPadding
                bold
                autoComplete="none"
                nativeID="display_name"
              />
            </Field>
          </View>
        )}
        <View style={[commonStyles.row]}>
          <Field
            label="First Name"
            style={[commonStyles.mr4, { flex: 1 }]}
            required={contact?.account_type === ACCOUNT_TYPES[0].value}
          >
            <StyledInput
              onChangeText={onChange("first_name")}
              defaultValue={contact?.first_name || ""}
              lessPadding
              bold
              autoComplete="none"
              nativeID="first_name"
            />
          </Field>
          <Field label="Last Name" style={[{ flex: 1 }]}>
            <StyledInput
              onChangeText={onChange("last_name")}
              defaultValue={contact?.last_name || ""}
              lessPadding
              bold
              autoComplete="none"
              nativeID="last_name"
            />
          </Field>
        </View>
        <View style={[commonStyles.row]}>
          <Field label="Account Type" style={[commonStyles.mr4, { flex: 1 }]}>
            <StyledPicker
              options={ACCOUNT_TYPES}
              lessPadding
              bold
              style={{ minWidth: "100%" }}
              selectedValue={contact?.account_type || ACCOUNT_TYPES[0]}
              onValueChange={(value) => {
                onChange("account_type")(value);
              }}
              nativeID="account_type"
            />
          </Field>
          <Field label="Assigned To" style={[{ flex: 1 }]}>
            <StyledPicker
              selectedValue={contact?.assigned_to || "Unassigned"}
              onValueChange={onChange("assigned_to")}
              options={teamMembers}
              lessPadding
              style={{ minWidth: "100%" }}
              bold
              nativeID="assigned_to"
            />
          </Field>
        </View>
        <Field label="Status">
          <StyledPicker
            options={OPTIONS}
            selectedValue={contact?.status || OPTIONS[2].value}
            onValueChange={onChange("status")}
            lessPadding
            style={{ minWidth: "100%" }}
            bold
            nativeID="status"
          />
        </Field>

        <Field label="Lead Source">
          {loadingLeadSources ? (
            <View
              style={{
                backgroundColor: palette.bg_light_grey,
                paddingHorizontal: 8,
                paddingVertical: 6,
                justifyContent: "center",
                alignItems: "center",
                borderRadius: 4,
                borderWidth: StyleSheet.hairlineWidth,
                borderColor: palette.light_grey,
              }}
            >
              <Text numberOfLines={null} style={{ fontSize: 12 }}>
                Loading Lead Sources
              </Text>
            </View>
          ) : (
            <StyledPicker
              options={[
                { name: "Select Lead Source", value: "" },
                ...leadSources?.map((leadSource) => ({
                  name: leadSource.name,
                  value: leadSource.id,
                })),
              ]}
              lessPadding
              bold
              style={{ minWidth: "100%" }}
              selectedValue={contact?.lead_source_id || ""}
              onValueChange={(val) => {
                if (!val) {
                  return;
                }
                const leadSource = leadSources.filter(
                  (leadSource) => `${leadSource.id}` === `${val}`,
                );
                if (leadSource.length > 0) {
                  updateContact({
                    lead_source_id: val,
                    lead_source: leadSource[0].name,
                  });
                }
              }}
              nativeID="lead_source_id"
            />
          )}
        </Field>
        {showContact && (
          <View style={[commonStyles.row]}>
            <View style={[commonStyles.mr4, { flex: 1 }]}>
              <Field label="Phone Number" required>
                <PhoneInput
                  onChange={onChange("phone")}
                  defaultCountryCode={contact?.countryCode}
                  country={country}
                  defaultValue={contact.phone}
                  lessPadding
                  bold
                  autoComplete="none"
                  nativeID="phone"
                />
              </Field>

              <Field label="Alternate Phone Number">
                <PhoneInput
                  onChange={onChange("alt_phone")}
                  country={country}
                  defaultValue={contact?.alt_phone}
                  lessPadding
                  bold
                  autoComplete="none"
                  nativeID="alt_phone"
                />
              </Field>
            </View>
            <View style={{ flex: 1 }}>
              <Field label="Email Address">
                <StyledInput
                  onChangeText={onChange("email")}
                  defaultValue={contact?.email || ""}
                  lessPadding
                  bold
                  autoComplete="none"
                  nativeID="email"
                />
              </Field>

              <Field label="Alternate Email">
                <StyledInput
                  onChangeText={onChange("alt_email")}
                  defaultValue={contact?.alt_email || ""}
                  lessPadding
                  bold
                  autoComplete="none"
                  nativeID="alt_email"
                />
              </Field>
            </View>
          </View>
        )}

        <Field
          label={
            (isAddressExpanded ? "- " : "+ ") +
            (useBillAddress ? "Service Address" : "Address")
          }
          style={[
            commonStyles.mt4,
            commonStyles.mb4,
            {
              zIndex: 50,
            },
          ]}
          isExpanded
          onExpand={setIsAddressExpanded}
        >
          {isAddressExpanded ? (
            <View style={[styles.sectionContent, { zIndex: 50 }]}>
              <Field
                label="Street Address"
                style={[
                  {
                    zIndex: 50,
                  },
                ]}
              >
                <AddressInput
                  value={contact?.address_1 || ""}
                  onChange={(data) => {
                    if (data?.suggested) {
                      const { street, zip, state, city } = data;
                      let address = {
                        address_1: street,
                        city: city,
                        state: state,
                        zip_code: zip,
                      };
                      updateContact(address);
                    } else {
                      updateContact({ address_1: data?.street || "" });
                    }
                  }}
                  nativeID="address_1"
                />
              </Field>
              <Field label="Street Address Line 2">
                <StyledInput
                  onChangeText={onChange("address_2")}
                  value={contact?.address_2 || ""}
                  lessPadding
                  bold
                  autoComplete="none"
                  nativeID="address_2"
                />
              </Field>
              <Row>
                <Col pr={5}>
                  <Field label="City">
                    <StyledInput
                      onChangeText={onChange("city")}
                      value={contact?.city || ""}
                      lessPadding
                      bold
                      autoComplete="none"
                      nativeID="city"
                    />
                  </Field>
                </Col>
                <Col pl={5}>
                  <Field label="State">
                    <StyledInput
                      onChangeText={onChange("state")}
                      value={contact?.state || ""}
                      lessPadding
                      bold
                      autoComplete="none"
                      nativeID="state"
                    />
                  </Field>
                </Col>
              </Row>
              <Field label="Zip Code">
                <StyledInput
                  onChangeText={onChange("zip_code")}
                  value={contact?.zip_code || ""}
                  lessPadding
                  bold
                  autoComplete="none"
                  nativeID="zip_code"
                />
              </Field>
            </View>
          ) : (
            <StyledInput
              value={address}
              onFocus={setIsAddressExpanded}
              lessPadding
              bold
              autoComplete="none"
              nativeID="address"
              multiline
              numberOfLines={getMultilineNumberOfLines(address)}
            />
          )}
        </Field>
        <View
          style={{
            paddingLeft: 8,
          }}
        >
          <CheckBox
            checked={useBillAddress}
            label="Use Separate Billing Address"
            onChange={(value) => {
              setUseBillAddress(value);
              if (isBillAddrExpanded) {
                setIsBillAddrExpanded();
                resetBillAddress();
              }
            }}
          />
        </View>
        {useBillAddress && (
          <Field
            label={(isBillAddrExpanded ? "- " : "+ ") + "Billing Address"}
            style={[commonStyles.mt4, commonStyles.mb4, { zIndex: 50 }]}
            onExpand={setIsBillAddrExpanded}
          >
            {isBillAddrExpanded ? (
              <View style={[styles.sectionContent, { zIndex: 50 }]}>
                <Field label="Street Address" style={[{ zIndex: 50 }]}>
                  <AddressInput
                    value={contact?.billing_address_1 || ""}
                    onChange={(data) => {
                      if (data?.suggested) {
                        const { street, zip, state, city } = data;
                        let address = {
                          billing_address_1: street,
                          billing_city: city,
                          billing_state: state,
                          billing_zip_code: zip,
                        };
                        updateContact(address);
                      } else {
                        updateContact({
                          billing_address_1: data?.street || "",
                        });
                      }
                    }}
                    nativeID="billing_address_1"
                  />
                </Field>

                <Field label="Street Address Line 2">
                  <StyledInput
                    onChangeText={onChange("billing_address_2")}
                    lessPadding
                    value={contact?.billing_address_2 || ""}
                    bold
                    autoComplete="none"
                    nativeID="billing_address_2"
                  />
                </Field>
                <Row>
                  <Col pr={5}>
                    <Field label="City">
                      <StyledInput
                        onChangeText={onChange("billing_city")}
                        value={contact?.billing_city || ""}
                        lessPadding
                        bold
                        autoComplete="none"
                        nativeID="billing_city"
                      />
                    </Field>
                  </Col>
                  <Col pl={5}>
                    <Field label="State">
                      <StyledInput
                        onChangeText={onChange("billing_state")}
                        value={contact?.billing_state || ""}
                        lessPadding
                        bold
                        autoComplete="none"
                        nativeID="billing_state"
                      />
                    </Field>
                  </Col>
                </Row>
                <Field label="Zip Code">
                  <StyledInput
                    onChangeText={onChange("billing_zip_code")}
                    value={contact?.billing_zip_code || ""}
                    lessPadding
                    bold
                    autoComplete="none"
                    nativeID="billing_zip_code"
                  />
                </Field>
              </View>
            ) : (
              <StyledInput
                value={billingAddress}
                onFocus={setIsBillAddrExpanded}
                lessPadding
                bold
                autoComplete="none"
                nativeID="billing_address"
                multiline
                numberOfLines={getMultilineNumberOfLines(billingAddress)}
              />
            )}
          </Field>
        )}
        <Field label="Notes" style={[commonStyles.mb4]}>
          <StyledInput
            value={contact?.notes || ""}
            onChangeText={onChange("notes")}
            lessPadding
            bold
            autoComplete="none"
            nativeID="notes"
            multiline
            numberOfLines={getMultilineNumberOfLines(contact?.notes)}
          />
        </Field>
        <Field label="Job Notes" style={[commonStyles.mb4]}>
          <StyledInput
            value={contact?.job_notes || ""}
            onChangeText={onChange("job_notes")}
            lessPadding
            bold
            autoComplete="none"
            nativeID="job_notes"
            multiline
            numberOfLines={getMultilineNumberOfLines(contact?.job_notes)}
          />
        </Field>
        {(!!contact?.remote_id || !!customerId) && !loading && (
          <>
            <LastJobComponent remote_id={contact?.remote_id || customerId} />
            <LastEstimateComponent
              remote_id={contact?.remote_id || customerId}
            />
          </>
        )}
      </View>
    </View>
  );

  return Form;
};

const LastJobComponent = ({ remote_id: remoteID }) => {
  const [loading, setLoading] = useState(true);
  const [job, setJob] = useState();
  const { contact } = useContext(ContactContext);

  const fetchLastJob = async () => {
    setLoading(true);

    try {
      const res = await FieldPulse.getLastJob(remoteID);

      if (!res.error) {
        let response = res?.response;
        if (response && response?.length > 0) {
          setJob(response[0]);
        }
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (remoteID) fetchLastJob();
  }, []);

  const status = useMemo(() => {
    if (!loading && job && remoteID) {
      return getLabelForStatusJob(job?.status);
    }
    return { value: "", label: "-", color: "black" };
  }, [loading, job, remoteID]);

  if (!remoteID) return null;

  const pressHandler =
    (edit = true) =>
    () => {
      const webappPostMessageEvent = WebAppPostMessage.createPageOpenEvent(
        JOB_OBJECT,
        edit ? OPEN_COMMAND : CREATE_COMMAND,
        edit ? { object_id: job.id } : {},
      );
      !edit &&
        webappPostMessageEvent.attachCustomer(filterCustomerForEvent(contact));
      webappPostMessageEvent.emitEvent();
    };

  return (
    <View style={{ marginBottom: 8 }}>
      <Row
        center
        style={{
          borderBottomWidth: StyleSheet.hairlineWidth,
          justifyContent: "space-between",
          borderColor: palette.light_grey,
        }}
        pb={2}
        mb={4}
      >
        <Heading size={5}>Last Job</Heading>
        <View>
          <SolidButton
            label={"Create Job"}
            labelStyle={{
              fontSize: 14,
              fontWeight: "500",
            }}
            color={palette.success}
            onPress={pressHandler(false)}
            faIcon={faCirclePlus}
          />
        </View>
      </Row>
      <Row pt={6} pb={6}>
        {loading && (
          <Row center>
            <ActivityIndicator />
          </Row>
        )}

        {!loading && !job && (
          <Row center>
            <Heading size={5} color={palette.label_grey}>
              No Job found.
            </Heading>
          </Row>
        )}

        {!loading && job && (
          <Row
            pb={6}
            pl={4}
            pr={4}
            style={{
              borderBottomWidth: StyleSheet.hairlineWidth,
              borderColor: palette.light_grey,
            }}
          >
            <Col size={9}>
              <TouchableOpacity onPress={pressHandler()}>
                <Text numberOfLines={1} style={{ color: palette.blue_link }}>
                  {job?.job_type || "-"}
                </Text>
              </TouchableOpacity>
            </Col>
            <Col size={3} right>
              <Text numberOfLines={1} style={{ color: palette.label_grey }}>
                {typeof status?.label === "string"
                  ? status?.label
                  : status?.label?.default}
              </Text>
            </Col>
          </Row>
        )}
      </Row>
    </View>
  );
};

const LastEstimateComponent = ({ remote_id: remoteID }) => {
  const [loading, setLoading] = useState(true);
  const [estimate, setEstimate] = useState();
  const { contact } = useContext(ContactContext);

  const fetchLastEstimate = useCallback(async () => {
    setLoading(true);
    try {
      const res = await FieldPulse.getLastEstimate(remoteID);

      if (!res.error) {
        let response = res?.response;
        if (response && response.length > 0) {
          setEstimate(response[0]);
        }
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  }, [remoteID]);

  const status = useMemo(() => {
    if (!loading && estimate) {
      if (estimate.status === 1) {
        return getLabelForStatusEstimate(estimate?.estimate_status);
      } else {
        return getLabelForStatusInvoice(estimate?.status);
      }
    }
    return { value: "", label: "-", color: "black" };
  }, [loading, estimate]);

  const pressHandler =
    (edit = true) =>
    () => {
      const webappPostMessageEvent = WebAppPostMessage.createPageOpenEvent(
        edit
          ? estimate?.status === 1
            ? ESTIMATE_OBJECT
            : INVOICE_OBJECT
          : INVOICE_OBJECT,
        edit ? OPEN_COMMAND : CREATE_COMMAND,
        edit ? { object_id: estimate.id } : {},
      );
      !edit &&
        webappPostMessageEvent.attachCustomer(filterCustomerForEvent(contact));
      webappPostMessageEvent.emitEvent();
    };

  useEffect(() => {
    if (remoteID) fetchLastEstimate();
  }, [remoteID]);

  if (!remoteID) return null;

  return (
    <View style={{ marginBottom: 16 }}>
      <Row
        center
        style={{
          borderBottomWidth: StyleSheet.hairlineWidth,
          justifyContent: "space-between",
          borderColor: palette.light_grey,
        }}
        pb={2}
        mb={4}
      >
        <Heading size={5}>Last Estimate/Invoice</Heading>
        <View>
          <SolidButton
            label={"Create Invoice"}
            labelStyle={{
              fontSize: 14,
              fontWeight: "500",
            }}
            color={palette.success}
            onPress={pressHandler(false)}
            faIcon={faCirclePlus}
          />
        </View>
      </Row>
      <Row pt={6} pb={6}>
        {loading && (
          <Row center>
            <ActivityIndicator />
          </Row>
        )}

        {!loading && !estimate && (
          <Row center>
            <Heading size={5} color={palette.label_grey}>
              No Estimate/Invoice found.
            </Heading>
          </Row>
        )}

        {!loading && estimate && (
          <Row
            pb={6}
            pl={4}
            pr={4}
            style={{
              borderBottomWidth: StyleSheet.hairlineWidth,
              borderColor: palette.light_grey,
            }}
          >
            <Col size={9}>
              <TouchableOpacity onPress={pressHandler()}>
                <Text numberOfLines={1} style={{ color: palette.blue_link }}>
                  {estimate?.title || "-"}
                </Text>
              </TouchableOpacity>
            </Col>
            <Col size={3} right>
              <Text numberOfLines={1} style={{ color: palette.label_grey }}>
                {status?.label || "-"}
              </Text>
            </Col>
          </Row>
        )}
      </Row>
    </View>
  );
};

export const Field = ({
  children,
  label,
  style = [],
  isExpanded: expanded = true,
  expandable = false,
  required,
  onExpand,
}) => {
  const [isExpanded, setIsExpanded] = useToggle(expanded);

  return (
    <View style={[styles.field, ...style]}>
      <View style={[styles.field_label]}>
        <Row>
          <Col>
            {expandable ? (
              <TouchableOpacity
                onPress={
                  typeof onExpand === "function" ? onExpand : setIsExpanded
                }
              >
                <Text style={[styles.field_label_text]}>
                  {expandable && (isExpanded ? "-" : "+")} {label}
                  {required && "*"}
                </Text>
              </TouchableOpacity>
            ) : typeof onExpand === "function" ? (
              <TouchableOpacity onPress={onExpand}>
                <Text style={[styles.field_label_text]}>
                  {label}
                  {required && "*"}
                </Text>
              </TouchableOpacity>
            ) : (
              <Text style={[styles.field_label_text]}>
                {label}
                {required && "*"}
              </Text>
            )}
          </Col>
        </Row>
      </View>
      {(!expandable || isExpanded) && children}
    </View>
  );
};

const styles = StyleSheet.create({
  topSaveBtn: {
    borderColor: "white",
    borderWidth: 1,
    borderRadius: 8,
    padding: 8,
  },
  customer_create_modal_container: {
    height: "100%",
    flexDirection: "column",
  },
  field: {
    marginBottom: 4,
  },
  field_label: {
    padding: 8,
    paddingBottom: 2,
  },
  field_label_required: {
    color: palette.grey,
    fontWeight: "600",
  },
  field_label_text: {
    fontSize: 12,
    fontWeight: "600",
    color: palette.grey,
    fontFamily: "OpenSans_600SemiBold",
  },
  modal_footer: {
    position: "absolute",
    bottom: 0,
    width: "100%",
  },
  error_message_container: {
    backgroundColor: "#f8d7da",
    padding: 12,
    borderRadius: 4,
    marginBottom: 8,
  },
  error_message_text: {
    color: palette.danger,
    textAlign: "center",
    fontFamily: "acumin-pro, san-serif",
    fontWeight: "600",
  },
  sectionContent: {
    paddingHorizontal: 16,
  },
});

export default CustomerForm;
