import { useLocation, useHistory } from "react-router-dom";
import {
  IssueReportProvider,
  useIssueReport,
} from "../../contexts/IssueReportContext";
import StarInput from "../../components/StarInput";
import { useCallback, useContext, useEffect, useState } from "react";
import { View, Text, StyleSheet, Linking } from "react-native";
import palette from "../../styles/palette";
import Heading from "../../components/Heading/index.web";
import { Strings } from "../../constants/strings";
import IconButton from "../../components/Buttons/IconButton";
import { faXmark } from "@fortawesome/pro-regular-svg-icons";
import { IssueReport } from "../../models/IssueReport";
import SolidButton from "../../components/Buttons/SolidButton";
import {
  CALL_REPORTING_CATEGORY_OPTIONS,
  CALL_REPORTING_CATEGORY_OTHER_ISSUE,
} from "../../constants/issueReport";
import StyledInput from "../../components/StyledInput";
import { Field } from "../../components/Customer/CustomerForm";
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";
import { faThumbsDown, faThumbsUp } from "@fortawesome/pro-solid-svg-icons";
import OutsidePressHandler from "react-native-outside-press";
import { CallsContext } from "../../contexts/CallsContext";
import commonStyles from "../../styles/common";
import UserContext from "../../contexts/UserContext";
import Communications from "react-native-communications";

const styles = StyleSheet.create({
  model_container: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(45, 62, 80, 0.79)",
    zIndex: 1000,
    position: "absolute",
  },
  model_view: {
    backgroundColor: palette.white,
    paddingVertical: 12,
    paddingHorizontal: 16,
    maxWidth: 450,
    borderRadius: 8,
  },
  call_quality_feedback_sub_text: {
    color: palette.label_grey,
    textAlign: "center",
    fontSize: 14,
    fontFamily: "OpenSans_600SemiBold",
    marginBottom: 16,
  },
  call_quality_feedback_header: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 8,
  },
  call_quality_feedback_general_message: {
    textAlign: "center",
    color: palette.label_grey,
    marginBottom: 8,
  },
  call_quality_feedback_category_btn: {
    marginBottom: 8,
    marginRight: 8,
    paddingHorizontal: 6,
    paddingVertical: 4,
    opacity: 1,
  },
  call_quality_feedback_category_container: {
    flexDirection: "row",
    flexWrap: "wrap",
  },
  call_quality_feedback_error_text: {
    color: palette.red,
    marginBottom: 8,
  },
  call_quality_feedback_submit_btn_container: { flexDirection: "row", gap: 8 },
});
const CallQualityFeedbackModal = () => {
  const location = useLocation();

  const { id, editable = true, reportedIssue = {} } = location?.state;
  if (!id) return null;

  return (
    <IssueReportProvider id={id} reportedIssue={reportedIssue}>
      <CallQualityFormContainer editable={editable} />
    </IssueReportProvider>
  );
};

const CallQualityFormContainer = ({ editable }) => {
  const history = useHistory();
  const { saving } = useIssueReport();
  const { isManagerRole } = useContext(UserContext);
  const onClose = () => {
    if (!saving) history.goBack();
  };

  const [submitted, setSubmitted] = useState(false);
  return (
    <View style={styles.model_container}>
      <OutsidePressHandler onOutsidePress={onClose}>
        <View style={styles.model_view}>
          <CallQualityFeedbackHeader onClose={onClose} />
          {!!submitted ? (
            <CallQualityFeedbackGreeting
              onClose={onClose}
              submitted={submitted}
              isManagerRole={isManagerRole}
            />
          ) : (
            <CallQualityFeedbackRating
              onClose={onClose}
              setSubmitted={setSubmitted}
              editable={editable}
            />
          )}
        </View>
      </OutsidePressHandler>
    </View>
  );
};

const CallQualityFeedbackGreeting = ({ onClose, submitted, isManagerRole }) => {
  const report = new IssueReport(submitted);
  const isLessThanFiveStarRating = report.isLessThanFiveStarRating;
  const isManager = isManagerRole();
  const onContactPress = () => {
    if (window.Intercom && isManager) {
      window.Intercom("showNewMessage", "");
      onClose();
    } else {
      try {
        Communications.email(
          [Strings.FIELDPULSE_SUPPORT_EMAIL],
          null,
          null,
          null,
          null,
        );
      } finally {
        onClose();
      }
    }
  };
  return (
    <View>
      <View style={commonStyles.alignCenter}>
        <FontAwesomeIcon
          icon={isLessThanFiveStarRating ? faThumbsDown : faThumbsUp}
          size={32}
          color={palette.blue_sky}
          style={commonStyles.mb4}
        />
        <Heading size={5} color={palette.primary} mb={8} mt={0}>
          {isLessThanFiveStarRating
            ? Strings.CALL_REPORTING_ISSUE_FACED_MESSAGE
            : Strings.CALL_REPORTING_ISSUE_NOT_FACED_MESSAGE}
        </Heading>
      </View>
      <Text style={styles.call_quality_feedback_sub_text}>
        {isLessThanFiveStarRating
          ? Strings.CALL_REPORTING_ISSUE_FACED_MESSAGE_SUB_TEXT
          : Strings.CALL_REPORTING_ISSUE_NOT_FACED_MESSAGE_SUB_TEXT}
      </Text>
      <SolidButton label={"Get in touch"} onPress={onContactPress} />
    </View>
  );
};

const CallQualityFeedbackHeader = ({ onClose }) => {
  const { saving } = useIssueReport();

  return (
    <View style={styles.call_quality_feedback_header}>
      <Heading size={3} mt={0} mb={0}>
        {Strings.CALL_REPORTING_HEADING}
      </Heading>
      <IconButton
        faPro
        faProIcon={faXmark}
        onPress={onClose}
        ml={0}
        mr={0}
        disabled={saving}
      />
    </View>
  );
};

const CallQualityFeedbackRating = ({ onClose, setSubmitted, editable }) => {
  const { report, setRating, submitFeedback, saving, error } = useIssueReport();
  const { addReportedCall } = useContext(CallsContext);
  const reportModel = new IssueReport(report);
  const rating = reportModel.rating;

  const onFormSubmit = useCallback(async () => {
    const res = await submitFeedback();
    if (!res.error) {
      setSubmitted(report);
      addReportedCall(report);
    }
  }, [report, submitFeedback]);

  return (
    <View>
      <Text style={styles.call_quality_feedback_general_message}>
        {Strings.CALL_REPORTING_GENERAL_MESSAGE}
      </Text>
      <View style={[commonStyles.alignCenter, commonStyles.mb4]}>
        <StarInput value={rating} onChange={setRating} editable={editable} />
      </View>
      {reportModel.isFiveStarRating && editable && (
        <SolidButton
          label={"Submit Feedback"}
          onPress={onFormSubmit}
          loading={saving}
        />
      )}
      {reportModel.isLessThanFiveStarRatingButNotZero && (
        <CallQualityFeedbackForm
          onClose={onClose}
          onSubmit={onFormSubmit}
          saving={saving}
          editable={editable}
        />
      )}
      {!!error && <Text style={{ color: palette.red }}>{error}</Text>}
    </View>
  );
};

const CallQualityFeedbackForm = ({
  onClose,
  onSubmit: onFormSubmit,
  saving,
  editable,
}) => {
  const { report, addCategory, removeCategory, setCategories, setDescription } =
    useIssueReport();
  const reportModel = new IssueReport(report);
  const categories = reportModel.categories;
  const [error, setError] = useState(false);

  const isFormValid = (set = true) => {
    setError(false);
    if (
      reportModel.isOtherIssue &&
      (!reportModel.description || !reportModel.description.trim())
    ) {
      if (set)
        setError({
          description: true,
          message: Strings.CALL_REPORTING_DESCRIPTION_REQUIRED,
        });
      return false;
    }
    if (!reportModel.categories || reportModel.categories.length === 0) {
      if (set)
        setError({
          category: true,
          message: Strings.CALL_REPORTING_CATEGORY_REQUIRED,
        });
      return false;
    }
    return true;
  };

  const onSubmit = async () => {
    if (!isFormValid()) {
      return;
    }

    await onFormSubmit();
  };

  const onCategoryPress = (value) => () => {
    if (value === CALL_REPORTING_CATEGORY_OTHER_ISSUE) {
      setCategories([CALL_REPORTING_CATEGORY_OTHER_ISSUE]);
    } else {
      if (categories.includes(value)) {
        removeCategory(value);
      } else {
        addCategory(value);
      }
    }
  };

  useEffect(() => {
    isFormValid(false);
  }, [report]);

  return (
    <View>
      <View style={styles.call_quality_feedback_category_container}>
        {CALL_REPORTING_CATEGORY_OPTIONS.map((option) => (
          <SolidButton
            key={option.value}
            label={option.label}
            onPress={onCategoryPress(option.value)}
            color={
              categories.includes(option.value)
                ? palette.blue_sky
                : palette.white
            }
            style={[
              styles.call_quality_feedback_category_btn,
              {
                cursor: editable ? "pointer" : "auto",
              },
            ]}
            containerStyle
            lessPadding
            labelStyle={{ fontSize: 14 }}
            disabled={!editable}
          />
        ))}
      </View>
      <Field
        required={reportModel.isOtherIssue}
        label={"Issue Description"}
        style={[{ marginBottom: error ? 8 : 16 }]}
      >
        <StyledInput
          value={report.description}
          onChangeText={setDescription}
          multiline
          autoGrow
          lessPadding
          editable={editable}
          containerStyle={{
            borderWidth: !!error.description ? 2 : 1,
            borderColor: !!error.description ? palette.red : palette.light_grey,
          }}
        />
      </Field>
      {!!error && (
        <Text style={styles.call_quality_feedback_error_text}>
          {error.message}
        </Text>
      )}
      {editable && (
        <View style={styles.call_quality_feedback_submit_btn_container}>
          <SolidButton
            label={"Submit Feedback"}
            onPress={onSubmit}
            loading={saving}
          />
          <SolidButton
            label={"Cancel"}
            color={palette.white}
            onPress={onClose}
            disabled={saving}
          />
        </View>
      )}
    </View>
  );
};

export default CallQualityFeedbackModal;
