import dayjs from "dayjs";
import React, { createContext, useState, useCallback, useRef } from "react";
import Engage from "~/services/engage";

export const CallsReportingContext = createContext({});
const LIMIT = 500;

export const CallsReportingProvider = ({ children }) => {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState();
  const [loadingMoreCalls, setLoadingMoreCalls] = useState();
  const [page, setPage] = useState(1);
  const [calls, setCalls] = useState([]);
  const [total, setTotal] = useState(0);
  const [startDate, setStartDate] = useState(() => {
    let newDate = new Date();
    newDate.setMonth(newDate.getMonth() - 1);
    return dayjs(newDate).format("YYYY-MM-DD");
  });

  const [endDate, setEndDate] = useState(
    dayjs(new Date()).format("YYYY-MM-DD"),
  );

  const getCallsReporting = useCallback(
    async (options = {}) => {
      if (loading) return;

      setLoading(true);
      let response = { data: [] };
      let params = {
        page: 1,
        start_date: startDate,
        end_date: endDate,
        limit: LIMIT,
      };
      setPage(() => 1);

      try {
        params.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      } catch {}

      const res = await Engage.getCallsReporting(params);

      if (!res.error) {
        const data = res?.data || [];
        if (res?.data) {
          response.data = data;
          setCalls(() => data);
        }
        if (!!res?.total) {
          setTotal(res?.total);
        }
      }
      setLoading(false);

      return response;
    },
    [setLoading, setCalls, startDate, endDate],
  );

  const loadMoreCalls = async () => {
    if (loadingMoreCalls) return;
    setLoadingMoreCalls(true);
    setPage((page) => page + 1);
    let response = { data: [] };
    try {
      let params = {
        page: page + 1,
        start_date: startDate,
        end_date: endDate,
        limit: LIMIT,
      };

      try {
        params.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      } catch {}

      const res = await Engage.getCallsReporting(params);

      if (!res.error) {
        const data = res?.data || [];
        if (res?.data) {
          setCalls((prevData) => {
            response.data = [...prevData, ...data];
            return [...prevData, ...data];
          });
        }
      }
    } catch (e) {}

    setLoadingMoreCalls(false);
    return response;
  };

  return (
    <CallsReportingContext.Provider
      value={{
        page,
        error,
        loading,
        calls,
        getCallsReporting,
        startDate,
        setStartDate,
        endDate,
        setEndDate,
        total,
        loadingMoreCalls,
        loadMoreCalls,
      }}
    >
      {children}
    </CallsReportingContext.Provider>
  );
};
