import { StyleSheet, Text, TouchableOpacity, View } from "react-native";
import palette from "../../../styles/palette";
import Heading from "../../../components/Heading/index.web";
import { Strings } from "../../../constants/strings";
import Row from "../../../layouts/Row";
import IconButton from "../../../components/Buttons/IconButton";
import { faExclamation, faXmark } from "@fortawesome/pro-regular-svg-icons";
import Col from "../../../layouts/Col";
import { Link } from "react-router-dom";

function CallQualityWarning({ warning, onClear }) {
  return (
    <View style={styles.call_quality_warning_stack_container}>
      <View>
        <Row center>
          <IconButton
            faPro
            faProIcon={faExclamation}
            onPress={null}
            iconSize={12}
            width={20}
            height={20}
            round
            color={palette.warning_darker}
            ml={2}
            mt={2}
            mr={10}
          />
        </Row>
      </View>
      <Col>
        <Row center style={{ justifyContent: "space-between" }} mb={6}>
          <Text style={styles.call_quality_warning_alert_title}>
            {warning?.alertTitle || Strings.CALL_QUALITY_DEFAULT_TITLE}
          </Text>
        </Row>
        <Text style={styles.call_quality_warning_alert_message}>
          {warning?.alertMessage || Strings.CALL_QUALITY_DEFAULT_MESSAGE}
        </Text>
        <Link
          to={{ pathname: Strings.CALL_QUALITY_WARNING_DOCS_URL }}
          target="_blank"
          style={{
            textDecoration: "none",
          }}
        >
          <Text style={styles.call_quality_warning_troubleshoot_link_text}>
            {Strings.GENERAL_TROUBLESHOOTING_GUIDE_LABEL}
          </Text>
        </Link>
      </Col>
      <View>
        <IconButton
          faPro
          faProIcon={faXmark}
          onPress={() => onClear(warning?.name)}
          iconSize={20}
          width={24}
          height={24}
          mr={0}
        />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  call_quality_warning_stack_container: {
    backgroundColor: palette.warning_bg,
    borderWidth: StyleSheet.hairlineWidth,
    borderColor: palette.warning_border,
    borderRadius: 6,
    paddingHorizontal: 24,
    paddingVertical: 20,
    marginTop: 4,
    flexDirection: "row",
  },
  call_quality_warning_stack_empty_container: {
    maxWidth: 800,
    backgroundColor: palette.bg_light_grey,
    borderBottomWidth: StyleSheet.hairlineWidth,
    borderLeftWidth: StyleSheet.hairlineWidth,
    borderRightWidth: StyleSheet.hairlineWidth,
    borderColor: palette.light_grey,
    borderBottomLeftRadius: 6,
    borderBottomRightRadius: 6,
    paddingHorizontal: 16,
    marginHorizontal: 16,
    alignItems: "center",
    cursor: "pointer",
  },
  call_quality_warning_troubleshoot_link_text: {
    color: palette.blue_link_v2,
    textDecoration: "underline",
  },
  call_quality_warning_alert_message: { color: palette.grey, marginBottom: 6 },
  call_quality_warning_alert_title: {
    fontFamily: "OpenSans_600SemiBold",
    fontSize: 16,
  },
});

export default CallQualityWarning;
