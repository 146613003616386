import React, {
  createContext,
  useReducer,
  useContext,
  useCallback,
  useMemo,
  useRef,
  useState,
  useEffect,
} from "react";
import { Platform } from "react-native";

import Engage from "../services/engage";
import useConversationSubscription from "../hooks/useConversationSubscription";
import useMediaLibrary from "../hooks/useMediaLibrary";
import { MediaContext } from "./MediaContext";
import NumbersContext from "./NumbersContext";
import useCache from "../hooks/useCache";

import {
  initConversation,
  conversationReducer,
} from "../reducers/conversationReducer";

import { isDevelopment } from "../constants/app";
import UserContext from "./UserContext";
import { ACCOUNT_TYPES } from "../constants/accountTypes";
import { ALL_INTERACTIONS_FILTER } from "../constants/conversations";
import GiftedChatMessage from "../models/GiftedChatMessage";

const ConversationContext = createContext(null);

const INITIAL_FILTER = {
  interaction_type: ALL_INTERACTIONS_FILTER,
  user: ALL_INTERACTIONS_FILTER,
};

let abortController = null;
let conversationAbortHandler = null;

export function ConversationProvider({ id, children }) {
  const { activeNumber, managedNumbers } = useContext(NumbersContext);
  const { setAssets } = useContext(MediaContext);
  const { assets } = useMediaLibrary();
  const [sending, setSending] = useState(false);
  const [loading, setLoading] = useState();
  const [loadingConversation, setLoadingConversation] = useState(); // This is specifically used only in the conversation view. As for `loading`, it is used in the other views like the `ParticipantDetails`.
  const [error, setError] = useState(null);
  const [page, setPage] = useState(1);
  const [activeNote, setActiveNote] = useState(null);
  const [saving, setSaving] = useState(false);
  const [updatedNotes, setUpdatedNotes] = useState({});
  const [activeTab, setActiveTab] = useState(0);
  const [tempHeader, setTempHeader] = useState("");
  const [newConversation, setNewConversation] = useState(false);
  const [hasCustomer, setHasCustomer] = useState(false);
  const [selectedAudio, setSelectedAudio] = useState(null);

  const [loadingOlderInteractions, setLoadingOlderInteractions] =
    useState(false);
  const [clearConversationTimeStamp, setClearConversationTimeStamp] =
    useState();
  const [filter, setFilter] = useState(INITIAL_FILTER);

  const { user } = useContext(UserContext);

  const [conversation, dispatch] = useReducer(
    conversationReducer,
    { id, twilio_number: activeNumber },
    initConversation,
  );

  const {
    participant,
    customer,
    twilio_number,
    interactions = [],
    pendingInteractions = [],
  } = conversation || {};

  const [customerInContacts, setCustomer] = useState(customer);
  const [phoneLine, setPhoneLine] = useState();
  const relatedConversations = useRef([]);

  useEffect(() => {
    if (twilio_number) {
      setPhoneLine(twilio_number);
    }
  }, [twilio_number]);

  /**
   *
   * getConversation(id)
   *
   * @param id String HashId of conversation to fetch
   * @return {} Conversation Object
   */
  const getConversation = useCallback(
    async (id, showLoading = true, fetchRelatedConversations = true) => {
      let res = { response: {} };
      try {
        if (!id && !conversation?.id && !participant?.phone_number) {
          return null;
        }

        conversationAbortHandler?.abort();
        conversationAbortHandler = new AbortController();

        if (showLoading) {
          setLoading(true);
        }
        setLoadingConversation(true);

        const conversation_id = id || conversation?.id;

        res = await Engage.getConversation(
          {
            id: conversation_id,
          },
          conversationAbortHandler.signal,
        );

        if (!res.error) {
          dispatch({ type: "SET_CONVERSATION", payload: res.response });
          setPage(res.page || 1);
          setNewConversation(false);
          if (fetchRelatedConversations) {
            getRelatedConversations(res.response.participant?.phone_number);
          }
          setLoading(false);
          setLoadingConversation(false);
        } else if (
          res.error.name !== "AbortError" &&
          res.error.name !== "CanceledError"
        ) {
          setError(res.message);
          setLoading(false);
          setLoadingConversation(false);
        }
      } finally {
        conversationAbortHandler = null;
      }

      return res.response;
    },
    [setLoading, setError, dispatch, managedNumbers],
  );

  /**
   *
   * getOlderInteractions(id)
   */
  const getOlderInteractions = useCallback(async () => {
    if (loading) {
      return conversation;
    }

    if (!id && !conversation?.id && !participant?.phone_number) {
      return conversation;
    }

    if (page >= conversation.total_pages) {
      return conversation;
    }

    setLoadingOlderInteractions(true);

    const conversation_id = id || conversation?.id;

    const res = await Engage.getConversation({
      id: conversation_id,
      page: page + 1,
    });

    if (res.error) {
      setError(res.message);
      // dispatch({ type: "CLEAR_CONVERSATION" });
    } else if (res.page !== page) {
      dispatch({
        type: "PREPEND_INTERACTIONS",
        payload: res.response.interactions,
      });

      setPage(res.page);
    }
    setLoadingOlderInteractions(false);

    return res.response;
  }, [participant, conversation, loading, setError, dispatch, page, setPage]);

  const getRelatedConversations = async (phoneNumber) => {
    if (phoneNumber) {
      const res = await Engage.getConversations({
        search: phoneNumber,
        filter: "all",
        limit: 100,
        managed_lines: managedNumbers.map((num) => num.id),
      });
      if (!res.error) {
        relatedConversations.current = res.response.map((conversation) => ({
          id: conversation.id,
          twilio_number_id: conversation.twilio_number.id,
          read: conversation?.unread_count === 0,
        }));
      } else {
        relatedConversations.current = [];
      }
    }
  };

  /**
   *
   * getConversationByParticipant(id)
   *
   * @param id:String HashId of conversation to fetch
   * @return {} Conversation Object
   */
  const getConversationByParticipant = useCallback(
    async ({ participant_id, remote_id, phone_number }) => {
      let res = { response: {} };
      try {
        abortController?.abort();
        abortController = new AbortController();
        setLoading(true);

        res = await Engage.getConversationByParticipant(
          {
            id: participant_id,
            remote_id: remote_id,
            phone: phone_number,
          },
          abortController.signal,
        );

        if (!res.error) {
          dispatch({ type: "SET_CONVERSATION", payload: res.response });
          setNewConversation(false);
          getRelatedConversations(res.response.participant?.phone_number);
          setLoading(false);
        } else if (
          res.error.name !== "AbortError" &&
          res.error.name !== "CanceledError"
        ) {
          setLoading(false);
        }
      } finally {
        abortController = null;
      }

      return res.response;
    },
    [setLoading, setError, dispatch],
  );

  /**
   *
   * setConversation(conversation)
   *
   * @param conversation:Object Object representing a conversation with at least an `id` property
   * @return Promise
   */
  const setConversation = useCallback(
    async (payload, shouldFetch = false) => {
      if (payload?.id) {
        dispatch({ type: "SET_CONVERSATION", payload });
        setNewConversation(false);

        if (shouldFetch) {
          await getConversation(payload.id);
        }
      } else {
        setError("An id is required to set conversation", payload);
      }
    },
    [dispatch, setError],
  );

  /**
   * setConversationById(id)
   * Sets conversation by hash ID and optionally fetches conversation
   *
   * @param id:String HashId of a conversation
   * @param shouldFetch:Boolean Flag to determine whether or not to fetch conversation [default=false]
   * @return Promise
   */
  const setConversationById = useCallback(
    async (id, shouldFetch = false) => {
      if (id) {
        dispatch({ type: "SET_CONVERSATION", payload: { id } });
        setNewConversation(false);

        if (shouldFetch) {
          await getConversation(id);
        }
      }
    },
    [dispatch, getConversation],
  );

  /**
   * openConversation(convo)
   * Sets conversation and marks it as read
   *
   * @param convo:Object Object representing a conversation
   * @param shouldFetch:Boolean Flag to determine whether or not to fetch conversation [default=false]
   * @return Promise
   */
  const openConversation = useCallback(
    async (payload, shouldFetch = false) => {
      if (!payload?.id) {
        setError("Conversation requires an id to be opened.", payload);
        return;
      }

      Engage.markConversationAsRead(payload.id);

      dispatch({ type: "SET_CONVERSATION", payload });
      setNewConversation(false);
      if (shouldFetch) {
        return await getConversation(id);
      }
    },
    [dispatch, setError, getConversation],
  );

  /**
   *
   * clearConversation()
   * Clears conversation data
   *
   * @return
   */
  const clearConversation = useCallback(() => {
    setClearConversationTimeStamp(new Date());
    dispatch({ type: "CLEAR_CONVERSATION" });
  }, [dispatch]);

  /**
   *
   * setParticipant(prtcpnt)
   * Sets the participant for the conversation
   *
   * @param prtcpnt:Object Object representing a participant
   * @return
   */
  const setParticipant = useCallback(
    (prtcpnt) => {
      // If participant hasn't changed... skip!
      // if (prtcpnt.phone_number === participant?.phone_number) return false;

      dispatch({
        type: "SET_PARTICIPANT",
        payload: {
          participant: prtcpnt,
          twilio_number: activeNumber,
        },
      });
    },
    [conversation, participant, activeNumber, dispatch],
  );

  /**
   *
   * setParticipantFromCustomer(customer, optionalNumber)
   * Sets the participant for the conversation using a customer record from FieldPulse API
   *
   * @param customer:Object Object representing a customer from the Fieldpulse API
   * @param optionalNumber:String - Used when the selected number isn't the default `phone` property
   * @return
   */
  const setParticipantFromCustomer = useCallback(
    async (customer, optionalNumber) => {
      // if (!customer?.id) return false;
      setLoading(true);
      // Fail safe in case a participant record is sent
      if (customer?.remote_id) {
        setParticipant(customer);
        return;
      }

      if (customer?.id) {
        // Optimistically update conversation with participant
        dispatch({
          type: "SET_CUSTOMER",
          payload: {
            customer: {
              ...customer,
              phone: optionalNumber || customer?.phone,
            },
            twilio_number: activeNumber,
          },
        });
      }
      // Create or update existing participant with customer data
      const res = await Engage.createParticipant({
        first_name:
          (customer?.account_type === ACCOUNT_TYPES[1].value
            ? customer?.company_name
            : customer?.first_name) || customer?.display_name,
        last_name:
          customer?.account_type === ACCOUNT_TYPES[1].value
            ? ""
            : customer?.last_name,
        email: customer?.email,
        phone_number: optionalNumber || customer?.phone,
        remote_id: customer?.id,
      });

      // Update conversation with participant response
      if (!res.error) {
        setParticipant(res.response);
      } else {
        setParticipant(customer);
      }
      setLoading(false);
      return res.response;
    },
    [activeNumber, setParticipant, dispatch],
  );

  /**
   *
   * onNewInteractionHandler(interaction)
   * Handles incoming "new-message" events from pusher
   *
   * @param interaction Object representing a new interaction
   * @return
   */
  const onNewInteractionHandler = useCallback(
    (interaction) => {
      if (isDevelopment) {
        console.log(
          "[ConversationContext:onNewInteractionHandler:interaction]",
          interaction,
        );
      }
      if (interaction && interaction.conversation_id == conversation?.id) {
        dispatch({ type: "APPEND_INTERACTIONS", payload: interaction });
      } else {
        console.error(
          "[ConversationContext:onNewInteractionHandler:INVALID_INTERACTION]",
          { interaction, conversation },
        );
      }
    },
    [dispatch, conversation],
  );

  // Subscribes to events for the current conversation
  const { socket_id } = useConversationSubscription({
    conversation,
    onNewInteractionHandler,
  });

  /**
   *
   * sendMessage(message)
   * Sends a new outgoing text message to API
   *
   * @param message:Object Object representing a new TwilioMessage interaction
   * @return
   */
  const sendMessage = useCallback(
    async (message) => {
      if (!twilio_number?.phone_number) {
        setError(
          "A twilio number is required to send a text message.",
          message,
        );
        return;
      }
      if (!participant?.phone_number) {
        setError(
          "An outgoing number is required to send a text message.",
          message,
        );
        return;
      }

      if (!message?.text && assets.length < 1) {
        setError("A message is required to send a text message.", message);
        return;
      }

      setSending(true);
      setFilter(() => INITIAL_FILTER);

      const _id = Math.round(Math.random() * 1000000);

      let interaction = {
        ...message,
        from: phoneLine.phone_number,
        to: participant.phone_number,
        pending: true,
        _id,
      };

      interaction.images = [];

      if (assets.length > 0) {
        assets.forEach((image) => {
          if (image._f) {
            interaction.images?.push(image._f);
          } else {
            interaction.images?.push({
              uri:
                Platform.OS === "android" ? `file://${image.path}` : image.path,
              name: image.fileName,
              type: image.mime, // it may be necessary in Android.
            });
          }
        });
      }
      let userData = {
        _id: twilio_number?.id,
        id: user?.id,
        name: user?.name,
        first_name: user?.first_name,
        last_name: user?.last_name,
        type: "user",
        remote_id: user?.remote_id,
        initials: user?.initials,
      };

      interaction = {
        ...interaction,
        note: null,
        author: userData,
        participant_id: participant?.id,
        conversation_id: conversation?.id,
        direction: "outbound-api",
        interactable_type: "TwilioMessage",
        twilio_number: conversation?.twilio_number,
        sent: false,
        system: false,
      };

      dispatch({
        type: "APPEND_PENDING_INTERACTION",
        payload: interaction,
      });
      setAssets([]);

      const res = await Engage.sendMessage(interaction, socket_id);

      if (res.error) {
        setError(res.message, res);
      } else {
        dispatch({
          type: "UPDATE_PENDING_INTERACTION",
          payload: {
            ...interaction,
            ...res.response,
            pending: false,
            _id,
          },
        });

        // If the conversation is new, we need to get this new conversation into the related conversations
        const relatedConversation = relatedConversations.current.find(
          (e) => e.twilio_number_id === phoneLine.id,
        );
        if (!relatedConversation) {
          getRelatedConversations(participant.phone_number);
        }
      }
      setSending(false);
      return res;
    },
    [
      twilio_number,
      participant,
      conversation,
      socket_id,
      setSending,
      dispatch,
      assets,
      setAssets,
      user,
      phoneLine,
    ],
  );

  const sendNote = useCallback(
    async (message) => {
      if (!twilio_number?.phone_number) {
        setError(
          "A twilio number is required to send a note message.",
          message,
        );
        return;
      }
      if (!participant?.phone_number) {
        setError(
          "An outgoing number is required to send a note message.",
          message,
        );
        return;
      }

      if (!message?.text) {
        setError("A message is required to send a note message.", message);
        return;
      }

      setSending(true);

      const _id = Math.round(Math.random() * 1000000);

      let interaction = {
        ...message,
        pending: true,
        _id,
      };

      const userData = {
        _id: twilio_number?.id,
        id: user?.id,
        name: user?.name,
        first_name: user?.first_name,
        last_name: user?.last_name,
        type: "user",
        remote_id: user?.remote_id,
        initials: user?.initials,
      };

      interaction = {
        ...interaction,
        author: userData,
        participant_id: participant?.id,
        conversation_id: conversation?.id,
        direction: "outbound-api",
        interactable_type: "TwilioNote",
        twilio_number: conversation?.twilio_number,
        sent: false,
        system: false,
      };

      dispatch({ type: "APPEND_PENDING_INTERACTION", payload: interaction });

      const res = await Engage.sendNote(interaction);

      if (res.error) {
        setError(res.message, res);
      } else {
        dispatch({
          type: "UPDATE_PENDING_INTERACTION",
          payload: {
            ...interaction,
            ...res.response,
            pending: false,
            _id,
          },
        });
      }
      setSending(false);
      return res;
    },
    [
      twilio_number,
      participant,
      conversation,
      socket_id,
      setSending,
      dispatch,
      assets,
      setAssets,
      user,
    ],
  );

  // A memoized array that combines pending and existing interactions
  const allInteractions = useMemo(() => {
    const results = [];
    for (let i = 0; i < interactions.length; i++) {
      const chatMessage = new GiftedChatMessage(interactions[i]);
      results.push(chatMessage);
    }
    for (let i = 0; i < pendingInteractions.length; i++) {
      const chatMessage = new GiftedChatMessage(pendingInteractions[i]);
      results.push(chatMessage);
    }
    return results;
  }, [interactions, pendingInteractions]);

  const updateCallNote = async (id, note) => {
    setSaving(true);
    await Engage.updateCallNote({ id, note });
    setUpdatedNotes((notes) => {
      let obj = notes;
      obj[id] = note;
      return obj;
    });
    setSaving(false);
  };

  const markAsStar = useCallback(
    async (id) => {
      const res = await Engage.markAsStar(id);
      if (res.error) {
        setError(res.error);
        return false;
      }
    },
    [setError],
  );

  const markAsUnread = useCallback(
    async (id) => {
      const res = await Engage.markAsUnread(id);
      if (res.error) {
        setError(res.error);
        return false;
      }
    },
    [setError],
  );

  useEffect(() => {
    setActiveNote(null);
    setUpdatedNotes({});
    if (!conversation?.id && participant?.id) {
      getConversationByParticipant({
        participant_id: participant?.id,
        remote_id: participant.remote_id,
        phone_number: participant?.phone_number || customer?.phone,
      });
    }
  }, [participant?.id, conversation.id]);

  useEffect(() => {
    if (activeTab === 1 && assets?.length > 0) {
      setAssets([]);
    }
  }, [activeTab, assets]);

  useEffect(() => {
    setActiveTab(0);
    setFilter(INITIAL_FILTER);
    setCustomer(customer);
    setSelectedAudio(null);
  }, [conversation?.id, clearConversationTimeStamp]);

  useEffect(() => {
    if (!loading && tempHeader) {
      setTempHeader("");
    }
  }, [loading, conversation?.id]);

  const setPhoneLineAndGetConversation = (value) => {
    let phoneLine;
    if (typeof value === "string") {
      // In web, the value is a phone number
      phoneLine = managedNumbers.find((e) => e.phone_number === value);
    } else {
      phoneLine = value;
    }
    if (!phoneLine) {
      return;
    }

    setPhoneLine(phoneLine);

    const relatedConversation = relatedConversations.current.find(
      (e) => e.twilio_number_id === phoneLine.id,
    );
    if (relatedConversation) {
      // Set the `showLoading` to false so that the `ParticipantView` is not reloaded
      getConversation(relatedConversation.id, false, false);
    } else {
      setConversation({ ...conversation, interactions: [] });
    }
  };

  return (
    <ConversationContext.Provider
      value={{
        loading,
        loadingConversation,
        sending,
        error,
        conversation: {
          ...conversation,
          interactions: allInteractions,
          page,
          customer: customerInContacts || customer,
        },
        getConversation,
        getConversationByParticipant,
        getOlderInteractions,
        getRelatedConversations,
        openConversation,
        setConversation,
        setConversationById,
        clearConversation,
        setParticipantFromCustomer,
        setParticipant,
        sendMessage,
        activeNote,
        setActiveNote,
        saving,
        updateCallNote,
        updatedNotes,
        activeTab,
        setActiveTab,
        sendNote,
        tempHeader,
        setTempHeader,
        newConversation,
        setNewConversation,
        hasCustomer,
        setHasCustomer,
        clearConversationTimeStamp,
        filter,
        setFilter,
        loadingOlderInteractions,
        markAsStar,
        markAsUnread,
        setCustomer,
        selectedAudio,
        setSelectedAudio,
        phoneLine,
        setPhoneLineAndGetConversation,
        relatedConversations,
      }}
    >
      {children}
    </ConversationContext.Provider>
  );
}

export default ConversationContext;
