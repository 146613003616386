import { IS_EMBEDDED, MAIN_WEBAPP_URLS } from "../constants/app/index.web.js";
import {
  AUTHORIZATION_COMMAND,
  CALL_ACCEPT_COMMAND,
  CALL_ACTION_COMMAND,
  CALL_END_COMMAND,
  CALL_EVENT_TYPE,
  CALL_MUTE_COMMAND,
  CALL_REJECT_COMMAND,
  CALL_START_COMMAND,
  CALL_STATUS_COMMAND,
  CLOSE_COMMAND,
  CUSTOMER_EVENT_TYPE,
  CUSTOMER_OBJECT,
  DEFAULT_MESSAGE_EVENT,
  ENGAGE_MANAGEMENT_EVENT_TYPE,
  ENGAGE_NOTIFICATION_EVENT_TYPE,
  ENGAGE_OBJECT,
  EXTERNAL_URL_TYPE,
  INTERNAL_URL_TYPE,
  NON_OPEN_PAGE_OBJECT,
  OPEN_COMMAND,
  OPEN_PAGE_EVENT_TYPE,
  OPEN_URL_COMMAND,
  POST_MESSAGE_EVENTS,
  READY_COMMAND,
  USER_STATUS_COMMAND,
  WEBAPP_MESSAGE_PROPERTIES,
} from "../constants/webapp-integration.js";
import { Model } from "./base.js";

export class WebAppPostMessage extends Model {
  constructor(type, ...rest) {
    if (!type) throw new Error("type is required on WebappPostMessageEvent");
    const data = { type, ...rest.map((e) => ({ ...e }))[0] };
    super(data);
    this.type = POST_MESSAGE_EVENTS[data.type];
    if (
      this.type !== OPEN_PAGE_EVENT_TYPE &&
      this.type !== ENGAGE_MANAGEMENT_EVENT_TYPE &&
      this.type !== ENGAGE_NOTIFICATION_EVENT_TYPE &&
      typeof this.data === "object"
    ) {
      this.data.object_type = NON_OPEN_PAGE_OBJECT[data.type];
    }
  }

  static createPageOpenEvent(object_type, command, data = {}) {
    return new WebAppPostMessage(OPEN_PAGE_EVENT_TYPE, {
      command,
      data: {
        ...data,
        object_type,
      },
    });
  }

  static createUserStatusEvent(status, user = { id: null, email: null }) {
    const { id, email } = user;
    return new WebAppPostMessage(ENGAGE_MANAGEMENT_EVENT_TYPE, {
      command: USER_STATUS_COMMAND,
      data: {
        is_logged: status,
        user: { id, email },
      },
    });
  }

  static createEngageNotificationEvent(badges) {
    try {
      if (!badges || typeof badges !== "object") return;
      const data = { ...badges };
      let totalCount = 0;
      Object.values(badges).forEach((value) => (totalCount += value));
      data.totalCount = totalCount;
      return new WebAppPostMessage(ENGAGE_NOTIFICATION_EVENT_TYPE, {
        command: CALL_STATUS_COMMAND,
        data,
      });
    } catch {}
  }

  static createCustomerOpenEvent(object) {
    return new WebAppPostMessage(OPEN_PAGE_EVENT_TYPE, {
      command: OPEN_COMMAND,
      data: {
        object,
        object_id: object?.id,
        object_type: CUSTOMER_OBJECT,
      },
    });
  }

  static createCloseEngageEvent() {
    return new WebAppPostMessage(OPEN_PAGE_EVENT_TYPE, {
      command: CLOSE_COMMAND,
      data: {
        object_type: ENGAGE_OBJECT,
      },
    });
  }

  static createEngageReadyStatusEvent() {
    return new WebAppPostMessage(ENGAGE_MANAGEMENT_EVENT_TYPE, {
      command: READY_COMMAND,
      data: {},
    });
  }

  static createOpenURLEvent(url, isExternal = true, action = OPEN_COMMAND) {
    return new WebAppPostMessage(ENGAGE_MANAGEMENT_EVENT_TYPE, {
      command: OPEN_URL_COMMAND,
      data: {
        url,
        type: isExternal ? EXTERNAL_URL_TYPE : INTERNAL_URL_TYPE,
        action,
      },
    });
  }

  getEvent() {
    let messageData = {};
    Object.keys(this).forEach((key) =>
      WEBAPP_MESSAGE_PROPERTIES.includes(key)
        ? (messageData[key] = this[key])
        : null,
    );
    return messageData;
  }

  attachCustomer(customer) {
    this.data = {
      ...this.data,
      extra: {
        ...this.data.extra,
        customer_id: customer?.id,
        customer,
      },
    };
  }

  emitEvent() {
    if (!IS_EMBEDDED) return;
    try {
      const event = this.getEvent();
      MAIN_WEBAPP_URLS.forEach((host) =>
        window.parent.postMessage(event, host),
      );
    } catch (e) {
      console.error(e);
    }
  }

  handleCallEvent(
    payload,
    onCallStart,
    onCallAccept,
    onCallEnd,
    onCallReject,
    onToggleMute,
  ) {
    const command = this?.command;
    if (!command) return;
    // switch on the command
    switch (command) {
      case CALL_START_COMMAND:
        const { phone_number, display_name, customer_id } = payload?.data || {};
        if (!phone_number) return;
        onCallStart(phone_number, {
          name: display_name,
          remote_id: customer_id,
        });
        break;
      case CALL_MUTE_COMMAND:
        onToggleMute(payload?.data?.mute);
        break;
      case CALL_ACTION_COMMAND:
        const action_type = payload?.data?.action_type;
        if (!action_type) return;
        // switch on action type
        switch (action_type) {
          case CALL_ACCEPT_COMMAND:
            onCallAccept(payload?.data?.call_sid);
            break;
          case CALL_REJECT_COMMAND:
            onCallReject(payload?.data?.call_sid);
            break;
          case CALL_END_COMMAND:
            onCallEnd();
            break;
          default:
            return;
        }
        break;
      default:
        return;
    }
  }

  handleCustomerEvent(payload, onOpenCustomer) {
    if (!payload?.data?.object_id) return;
    onOpenCustomer(payload?.data?.object_id);
  }

  handleEngageManagementEvent(payload, login, logout) {
    const command = this?.command;
    if (!command) return;
    switch (command) {
      case USER_STATUS_COMMAND:
        if (!payload?.data?.is_logged) {
          logout();
        }
        break;
      case AUTHORIZATION_COMMAND:
        if (!!payload?.data?.token) {
          login(payload?.data?.token);
        }
      default:
        return;
    }
  }

  handleEvent(
    event,
    onCallStart,
    onCallAccept,
    onCallEnd,
    onCallReject,
    onToggleMute,
    onOpenCustomer,
    login,
    logout,
  ) {
    const payload = event?.data;
    // switch on event type
    switch (this.type) {
      case CALL_EVENT_TYPE:
        this.handleCallEvent(
          payload,
          onCallStart,
          onCallAccept,
          onCallEnd,
          onCallReject,
          onToggleMute,
        );
        break;
      case CUSTOMER_EVENT_TYPE:
        this.handleCustomerEvent(payload, onOpenCustomer);
        break;
      case ENGAGE_MANAGEMENT_EVENT_TYPE:
        this.handleEngageManagementEvent(payload, login, logout);
        break;
      default:
        return;
    }
  }

  get isMainAppAuthEvent() {
    try {
      return (
        this.type === ENGAGE_MANAGEMENT_EVENT_TYPE &&
        (this.command === AUTHORIZATION_COMMAND ||
          this.command === USER_STATUS_COMMAND)
      );
    } catch {
      return false;
    }
  }

  get defaults() {
    return DEFAULT_MESSAGE_EVENT;
  }
}
