import { INVOICE_LIST_VISIBILITY_HIDE } from "./invoices";
import { ASSET_CREATE_VISIBILITY } from "./users";

// events
export const CALL_EVENT_TYPE = "call";
export const OPEN_PAGE_EVENT_TYPE = "open-page";
export const CUSTOMER_EVENT_TYPE = "customer";
export const ENGAGE_MANAGEMENT_EVENT_TYPE = "engage-management";
export const ENGAGE_NOTIFICATION_EVENT_TYPE = "engage-notification";

export const POST_MESSAGE_EVENTS = {
  [CALL_EVENT_TYPE]: CALL_EVENT_TYPE,
  [OPEN_PAGE_EVENT_TYPE]: OPEN_PAGE_EVENT_TYPE,
  [CUSTOMER_EVENT_TYPE]: CUSTOMER_EVENT_TYPE,
  [ENGAGE_MANAGEMENT_EVENT_TYPE]: ENGAGE_MANAGEMENT_EVENT_TYPE,
  [ENGAGE_NOTIFICATION_EVENT_TYPE]: ENGAGE_NOTIFICATION_EVENT_TYPE,
};

// commands
// call
export const CALL_START_COMMAND = "start";
export const CALL_ACTION_COMMAND = "action";
export const CALL_MUTE_COMMAND = "mute";
export const CALL_END_COMMAND = "end";
export const CALL_REJECT_COMMAND = "reject";
export const CALL_STATUS_COMMAND = "status";
export const USER_STATUS_COMMAND = "status";
export const CALL_ACCEPT_COMMAND = "accept";

// forms
export const CREATE_COMMAND = "create";
export const EDIT_COMMAND = "edit";
export const CLOSE_COMMAND = "close";
export const OPEN_COMMAND = "open";

// objects
export const CALL_OBJECT = "call";
export const PROJECT_OBJECT = "project";
export const JOB_OBJECT = "job";
export const INVOICE_OBJECT = "invoice";
export const ESTIMATE_OBJECT = "estimate";
export const PURCHASE_ORDER_OBJECT = "purchase-order";
export const FORM_OBJECT = "form";
export const MAINTENANCE_AGREEMENT_OBJECT = "maintenance-agreement";
export const ASSETS_OBJECT = "asset";
export const DYNAMIC_PROPOSAL_OBJECT = "dynamic-proposal";
export const CUSTOMER_OBJECT = "customer";
export const ENGAGE_OBJECT = "engage";
export const USER_OBJECT = "user";

// Management
export const AUTHORIZATION_COMMAND = "authorization";
export const READY_COMMAND = "ready";
export const OPEN_URL_COMMAND = "url";
export const EXTERNAL_URL_TYPE = "external";
export const INTERNAL_URL_TYPE = "internal";

export const NON_OPEN_PAGE_OBJECT = {
  customer: CUSTOMER_OBJECT,
  call: CALL_OBJECT,
};

export const WEBAPP_MESSAGE_PROPERTIES = ["type", "object", "command", "data"];

export const DEFAULT_MESSAGE_EVENT = {
  type: "",
  command: "",
  data: {},
  source: "fe-engage",
};

export const OPEN_PAGE_PRIMARY_OPTIONS = [
  {
    label: "Open Full Profile",
    value: CUSTOMER_OBJECT,
  },
];

export const OPEN_PAGE_RELATED_OPTIONS = [
  {
    label: "Create Project",
    value: PROJECT_OBJECT,
    filter: ({ company, isManagerRole }) =>
      company.projects_enabled && isManagerRole(),
  },
  {
    label: "Create Job",
    value: JOB_OBJECT,
    filter: ({ isManagerRole }) => isManagerRole(),
  },
  {
    label: "Create Estimate",
    value: ESTIMATE_OBJECT,
    filter: ({ user, isManagerRole }) =>
      isManagerRole() ||
      (user.invoicing_enabled &&
        user.invoice_list_permission !== INVOICE_LIST_VISIBILITY_HIDE),
  },
  {
    label: "Create Invoice",
    value: INVOICE_OBJECT,
    filter: ({ user, isManagerRole }) =>
      isManagerRole() ||
      (user.invoicing_enabled &&
        user.invoice_list_permission !== INVOICE_LIST_VISIBILITY_HIDE),
  },
  {
    label: "Create Purchase Order",
    value: PURCHASE_ORDER_OBJECT,
    filter: ({ company, isAdminRole, user, isOnlyManagerRole }) =>
      company.is_purchase_order_enabled &&
      (isAdminRole() || (isOnlyManagerRole() && user.can_view_orders)),
  },
  {
    label: "Create Form",
    value: FORM_OBJECT,
    filter: ({ company }) => company.forms_enabled,
  },
  {
    label: "Create Maintenance Agreement",
    value: MAINTENANCE_AGREEMENT_OBJECT,
    filter: ({ company, isManagerRole, user }) =>
      company.is_maintenance_agreements_enabled &&
      (isManagerRole() || user.sa_able_create_ma),
  },
  {
    label: "Create Dynamic Proposal",
    value: DYNAMIC_PROPOSAL_OBJECT,
    filter: ({ company }) => company.is_detailed_proposal_enabled,
  },
  {
    label: "Create Asset",
    value: ASSETS_OBJECT,
    filter: ({ company, user }) =>
      company.is_asset_enabled &&
      ASSET_CREATE_VISIBILITY.includes(user.asset_visibility),
  },
];
