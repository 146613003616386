import { createContext, useReducer } from "react";
import { callQualityWarningReducer } from "../reducers/callQualityWarningReducer";
import {
  CALL_QUALITY_WARNING_ACTION_TYPES,
  INIT_WARNINGS_STATE,
} from "../constants/callQualityWarning";
import useTwilio from "../hooks/useTwilio";
import { useEffect } from "react";

const CallQualityWarningsContext = createContext({});

export const CallQualityWarningsProvider = ({ children }) => {
  const [callQualityWarningsState, callQualityWarningsDispatch] = useReducer(
    callQualityWarningReducer,
    INIT_WARNINGS_STATE,
  );
  const warnings = callQualityWarningsState.warnings;
  const hiddenWarnings = callQualityWarningsState.hiddenWarnings;

  const addWarning = (callSid, warningName) => {
    callQualityWarningsDispatch({
      type: CALL_QUALITY_WARNING_ACTION_TYPES.ADD_WARNING,
      payload: {
        callSid,
        warningName,
      },
    });
  };

  const clearWarning = (warningName) => {
    callQualityWarningsDispatch({
      type: CALL_QUALITY_WARNING_ACTION_TYPES.CLEAR_WARNING,
      payload: {
        warningName,
      },
    });
  };

  const hideWarning = (warningName) => {
    callQualityWarningsDispatch({
      type: CALL_QUALITY_WARNING_ACTION_TYPES.HIDE_WARNING,
      payload: {
        warningName,
      },
    });
  };

  const hideAllWarnings = () => {
    callQualityWarningsDispatch({
      type: CALL_QUALITY_WARNING_ACTION_TYPES.HIDE_ALL_WARNING,
    });
  };

  const reset = () => {
    callQualityWarningsDispatch({
      type: CALL_QUALITY_WARNING_ACTION_TYPES.RESET,
    });
  };

  return (
    <CallQualityWarningsContext.Provider
      value={{
        warnings,
        addWarning,
        clearWarning,
        hideWarning,
        hideAllWarnings,
        reset,
        hiddenWarnings,
      }}
    >
      {children}
    </CallQualityWarningsContext.Provider>
  );
};

export default CallQualityWarningsContext;
