import { CALL_QUALITY_WARNING_TYPES } from "../constants/callQualityWarning";
import { Strings } from "../constants/strings";
import { Model } from "./base";

export class CallQualityWarning extends Model {
  name;
  callSids = [];
  hiddenCallSids = [];
  timestamp;
  alertTitle;
  alertMessage;

  constructor(data) {
    super(data);

    if (
      data?.callSid &&
      !this.callSids.includes(data.callSid) &&
      !this.hiddenCallSids.includes(data.callSid)
    ) {
      this.callSids.push(data.callSid);
    }

    if (data?.timestamp == null) {
      this.timestamp = new Date();
    } else {
      this.timestamp = data.timestamp;
    }

    const alert = CALL_QUALITY_WARNING_TYPES[this.name];

    this.alertTitle = alert?.title ?? Strings.CALL_QUALITY_DEFAULT_TITLE;
    this.alertMessage = alert?.message ?? Strings.CALL_QUALITY_DEFAULT_MESSAGE;
  }
}
