import { faStar } from "@fortawesome/pro-solid-svg-icons";
import { faStar as faStarRegular } from "@fortawesome/pro-regular-svg-icons";
import IconButton from "../Buttons/IconButton";
import { StyleSheet, View } from "react-native";
import palette from "../../styles/palette";

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "center",
  },
});

const StarInput = ({
  value = 0,
  onChange = () => {},
  maxStars = 5,
  style = {},
  ...props
}) => {
  return (
    <View style={[styles.container, style]}>
      {[...Array(maxStars)].map((_, index) => (
        <Star
          key={index}
          index={index}
          checked={index < value}
          onChange={onChange}
          {...props}
        />
      ))}
    </View>
  );
};

const Star = ({ index, checked, onChange, editable = true, ...props }) => {
  const Icon = checked ? faStar : faStarRegular;
  const onPress = () => onChange(index + 1);
  return (
    <IconButton
      faPro
      faProIcon={Icon}
      onPress={onPress}
      mr={0}
      ml={0}
      iconColor={checked ? palette.yellow : palette.light_grey}
      disabled={!editable}
      {...props}
      style={{
        opacity: 1,
        cursor: editable ? "pointer" : "auto",
        ...props.style,
      }}
    />
  );
};

export default StarInput;
