// FieldPulse Api returns mountains of data that we don't need..
// In order to trim our memory foot print.. and prevent OutOfMemory crashes..
// only keep data we need.
import { getFullName } from "~/helpers/text";
import { ACCOUNT_TYPES } from "../constants/accountTypes";
import { parsePhoneNumber } from "libphonenumber-js";
import { validEmail } from "./text";

export const filterCustomer = (customer) => {
  if (!customer) return {};

  const {
    id,
    company_id,
    first_name,
    last_name,
    company_name,
    status,
    address_1,
    address_2,
    city,
    state,
    zip_code,
    assigned,
    assigned_to,
    country_code,
    email,
    phone,
    phone_e164,
    has_valid_phone,
    alt_email,
    alt_phone,
    secondary_email,
    secondary_phone,
    updated_at,
    lead_source,
    lead_source_id,
    notes,
    job_notes,
    display_name,
    account_type,
    main_location,
    first_locations,
    billing_address_1,
    billing_address_2,
    billing_state,
    billing_city,
    billing_zip_code,
    is_tax_exempt,
  } = customer;

  const assignedData = assigned ? filterAssigned(assigned) : null;

  return {
    id,
    company_id,
    first_name,
    last_name,
    name: `${first_name || ""} ${last_name || ""}`.trim(),
    company_name,
    status,
    address_1,
    address_2,
    city,
    state,
    zip_code,
    assigned: assignedData,
    assigned_to,
    country_code,
    email,
    phone,
    phone_e164,
    has_valid_phone,
    alt_email,
    alt_phone,
    secondary_email,
    secondary_phone,
    updated_at,
    lead_source,
    lead_source_id,
    notes,
    job_notes,
    display_name,
    account_type,
    main_location,
    first_locations,
    billing_address_1,
    billing_address_2,
    billing_state,
    billing_city,
    billing_zip_code,
    is_tax_exempt,
  };
};

export const filterCustomerWithRelations = (customer) => {
  if (!customer) return {};

  const customerData = filterCustomer(customer);
  const { invoices, jobs, tags, customfields, estimates, files } = customer;
  return {
    ...customerData,
    invoices,
    jobs,
    tags,
    customfields,
    estimates,
    files,
  };
};

export const filterCustomerForEvent = (customer) => {
  if (!customer) return {};

  const filteredCustomerData = filterCustomer(customer);
  if (!filteredCustomerData) return {};
  delete filteredCustomerData.name;
  delete filteredCustomerData.has_valid_phone;
  return filteredCustomerData;
};

export const filterAssigned = (assigned) => {
  const { id, avatar, first_name, last_name, email, role, founder } =
    assigned || {};
  return {
    id,
    avatar,
    first_name,
    last_name,
    email,
    role,
    founder,
  };
};

export const getCustomerName = (customer) => {
  if (customer) {
    return customer.account_type === "company"
      ? customer.company_name ?? ""
      : getFullName(customer.first_name, customer.last_name);
  }
  return "";
};

export const getFullAddress = (customer, addressType = "normal") => {
  if (customer) {
    let addressItems = [];
    if (addressType === "normal") {
      addressItems = [
        customer.address_1,
        customer.address_2,
        customer.city,
        customer.state,
        customer.zip_code,
      ].filter((e) => e != null && e !== "");
    } else if (addressType === "billing") {
      addressItems = [
        customer.billing_address_1,
        customer.billing_address_2,
        customer.billing_city,
        customer.billing_state,
        customer.billing_zip_code,
      ].filter((e) => e != null && e !== "");
    }
    return addressItems.join(", ");
  }
  return "";
};

export const checkCustomerFormValid = (customer) => {
  if (!customer) return false;

  if (
    customer?.account_type === ACCOUNT_TYPES[0].value &&
    !customer?.first_name
  )
    return false;

  if (
    customer?.account_type === ACCOUNT_TYPES[1].value &&
    !customer?.company_name
  )
    return false;

  if (!customer?.phone) return false;

  try {
    const phone = parsePhoneNumber(customer.phone, "US");
    if (phone.nationalNumber.length < 10) return false;
    if (customer.alt_phone) {
      const altPhone = parsePhoneNumber(customer.alt_phone, "US");
      if (altPhone.nationalNumber.length < 10) return false;
    }
  } catch {
    return false;
  }

  if (!customer?.display_name) return false;

  return true;
};

export const checkContactFormValid = (contact) => {
  if (!contact) {
    return false;
  }

  if (!contact?.first_name || !contact?.first_name?.trim()) {
    return false;
  }

  if (!contact?.customer_id) {
    return false;
  }

  if (!contact?.phone) {
    return false;
  }

  if (contact?.email && !validEmail(contact?.email)) {
    return false;
  }

  try {
    const phone = parsePhoneNumber(contact.phone, "US");
    if (phone.nationalNumber.length < 10) {
      return false;
    }
    if (contact.alt_phone) {
      const altPhone = parsePhoneNumber(contact.alt_phone, "US");
      if (altPhone.nationalNumber.length < 10) {
        return false;
      }
    }
  } catch (e) {
    return false;
  }

  return true;
};
