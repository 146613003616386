import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { StyleSheet, View, Text, ScrollView } from "react-native";

import Screen from "~/layouts/Screen/index.web";
import Row from "~/layouts/Row";
import Col from "~/layouts/Col";

import useTwilio from "~/hooks/useTwilio";
import CallLogList from "~/components/CallLogList/index.web";
import CallsHeader from "~/components/Headers/CallsHeader/index.web";
import DialerPanel from "~/components/Dialer/Panel/index.web";
import ActiveCallPanel from "~/screens/ActiveCallScreen/Panel/index.web";

import { CallsProvider } from "~/contexts/CallsContext";
import palette from "~/styles/palette";
import { BadgeContext } from "~/contexts/BadgeContext";
import UserContext from "~/contexts/UserContext";
import NumbersContext from "~/contexts/NumbersContext";
import { Link } from "react-router-dom";
import { CallsContext } from "../../contexts/CallsContext";
import Heading from "../../components/Heading/index.web";
import CustomerForm from "../../components/Customer/CustomerForm";
import ContactContext, { ContactProvider } from "../../contexts/ContactContext";
import SolidButton from "../../components/Buttons/SolidButton";
import MenuBar from "../../components/MenuBar/index.web";
import ParticipantDetails from "../../components/Participant/ParticipantDetails/index.web";
import { getSmartTimestamp } from "../../helpers/day";
import { getCommaSeparatedHoursMinutesSeconds } from "../../helpers/text";
import ReactAudioPlayer from "react-audio-player";
import StyledInput from "../../components/StyledInput";
import Engage from "../../services/engage";
import IconButton from "../../components/Buttons/IconButton";
import ConversationContext from "../../contexts/ConversationContext";
import {
  faBan,
  faCheck,
  faComments,
  faFlag,
  faLink,
  faPhone,
} from "@fortawesome/pro-regular-svg-icons";
import { faFlag as faFlagSolid } from "@fortawesome/pro-solid-svg-icons";
import { parsePhoneNumber } from "libphonenumber-js";
import { useHistory } from "react-router-dom";
import CopyButton from "../../components/Buttons/CopyButton/index.web";
import commonStyles from "../../styles/common";
import Clipboard from "@react-native-clipboard/clipboard";
import CustomersContext from "../../contexts/CustomersContext";
import { BlockedBanner } from "../../components/BlockedBanner";
import { Strings } from "../../constants/strings";

export default function CallsScreen({}) {
  const { user } = useContext(UserContext);
  const { calls, voicemails, messages } = useContext(BadgeContext);

  const { activeNumber } = useContext(NumbersContext);

  const { selected, customerFormVisible } = useContext(CallsContext);
  const { setConversationById } = useContext(ConversationContext);
  const [participant, setParticipant] = useState({});

  useEffect(() => {
    if (selected && selected !== null) {
      let participant = {};

      participant.lead_source_id = selected?.twilio_number?.lead_source_id;
      participant.lead_source_name = selected?.twilio_number?.lead_source_name;
      setParticipant(participant);
      setConversationById(selected?.conversation_id, true);
    } else {
      setParticipant({});
    }
  }, [selected]);

  return (
    <Screen>
      <View style={[styles.container]}>
        <Col>
          <Row style={[styles.inner]}>
            <Col size={3} style={[styles.listPanel]}>
              <MenuBar />
              {activeNumber && <CallLogList />}
            </Col>
            <Col style={[styles.centerPanel]}>
              {customerFormVisible ? (
                <CustomerCreatePanel />
              ) : selected === null ? (
                <>
                  <View>
                    {user?.first_name ? (
                      <Text style={[styles.greeting]}>
                        Hi, {user.first_name}!
                      </Text>
                    ) : (
                      <Text style={[styles.greeting]}>Hi!</Text>
                    )}
                  </View>
                  {activeNumber ? (
                    <Text style={[styles.text]}>
                      {messages + calls + voicemails > 0
                        ? `You have `
                        : `No missed calls or voicemails.`}
                      {!!messages && (
                        <Text>
                          {messages} unread message{messages > 1 ? "s" : ""}
                        </Text>
                      )}
                      {!!messages && !!calls && !!voicemails && (
                        <>
                          {", "}
                          <br />
                        </>
                      )}
                      {!!messages && !!calls && !voicemails && (
                        <>
                          <br /> {"and "}
                        </>
                      )}
                      {!!calls && (
                        <>
                          <Text>
                            {calls} missed call{calls > 1 ? "s" : ""}
                          </Text>
                          <br />
                        </>
                      )}
                      {(!!calls || !!messages) && " and "}
                      {!!voicemails && <Text>{voicemails} new voicemails</Text>}
                    </Text>
                  ) : (
                    <>
                      <Text style={[styles.text]}>
                        You don't have an Active Number.
                      </Text>
                      <Link
                        to="/settings/number"
                        style={{ textDecoration: "none" }}
                      >
                        <Text style={[styles.link]}>Manage Numbers</Text>
                      </Link>
                    </>
                  )}
                </>
              ) : (
                <View
                  style={{
                    flex: 1,
                    textAlign: "left",
                    width: "100%",
                  }}
                >
                  <CallLogDetails />
                </View>
              )}
            </Col>
            <Col size={3} style={[styles.rightPanel]}>
              <View
                style={{
                  backgroundColor: "white",
                  height: 75,
                  borderColor: palette.light_grey,
                  borderBottomWidth: 1,
                }}
              >
                <Col leftCenter pl={16}>
                  <Row pt={8} center>
                    <Heading size={3} color={palette.label_grey} mt={0} mb={0}>
                      Profile Info
                    </Heading>
                    <Col right>
                      <Row right>
                        <IconButton
                          iconName="phone"
                          mt={0}
                          mb={0}
                          style={{
                            marginTop: 0,
                            marginBottom: 4,
                            borderRadius: 4,
                          }}
                          border
                          round={false}
                          mr={0}
                          iconColor={palette.green}
                          color={palette.light_green}
                          borderColor={palette.green}
                          // disabled={
                          //   !activeNumber ||
                          //   activeCall ||
                          //   !activeCustomer.phone_e164
                          // }
                          // onPress={onCallPress}
                        />
                        <IconButton
                          iconName="message-circle"
                          mt={0}
                          mb={0}
                          style={{
                            marginTop: 0,
                            marginBottom: 4,
                            borderRadius: 4,
                          }}
                          border
                          round={false}
                          iconColor={palette.primary}
                          color={palette.light_blue}
                          borderColor={palette.primary}
                          // disabled={!activeNumber || !activeCustomer.phone_e164}
                          // onPress={onTextPress}
                        />
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </View>
              <ParticipantDetails customer={participant} />
            </Col>
          </Row>
        </Col>
      </View>
    </Screen>
  );
}

export const CallLogDetails = ({ setPanelVisible }) => {
  const { selected, setSelected } = useContext(CallsContext);
  const { setCallQueue } = useTwilio();
  const { activeNumber } = useContext(NumbersContext);
  const { user, canSeeCustomerContact, isAdmin } = useContext(UserContext);
  const { blockedContacts } = useContext(CustomersContext);
  const [copied, setCopied] = useState(false);
  const isCustomerSaved = selected?.author?.remote_id !== null;
  const hasParent = !!selected?.author?.parent;
  const parentName = selected?.author?.parent?.name;
  const hasMultipleCallRecordings = selected?.meta?.call_recordings?.length > 1;
  const isBlocked =
    selected?.author?.is_blocked ||
    blockedContacts?.includes(selected?.author?.id);
  const { reported, reportedIssue } = selected;

  const history = useHistory();
  const phoneNumber = useMemo(() => {
    if (selected !== null) {
      const { to, from, direction } = selected;
      let phone = to;

      if (direction === "inbound") {
        phone = from;
      }

      return phone;
    }
    return "";
  }, [selected]);

  const callStatus = useMemo(() => {
    if (selected !== null) {
      if (selected.missed) {
        return "Missed";
      }

      if (selected.direction === "inbound") {
        return "Inbound";
      }

      return "Outbound";
    }

    return "-";
  }, [selected]);

  const phoneLineUsed = useMemo(() => {
    if (selected !== null) {
      const { twilio_number } = selected;
      let phone = twilio_number?.phone_number;
      try {
        let formattedPhone = parsePhoneNumber(phone).formatNational();
        phone = formattedPhone;
      } catch {}

      return phone;
    }
    return "";
  }, [selected, user?.account?.country]);

  const onCallPress = () => {
    setCallQueue({ to: phoneNumber, metaData: selected.author });
  };

  const onReportPress = () => {
    const props = { id: selected._id };
    if (reported) {
      props.editable = false;
      props.reportedIssue = reportedIssue;
    }
    history.push({ hash: "report-call", state: props });
  };

  const onCopyAllRecordingsPress = () => {
    setCopied(true);
    const text = `${selected?.meta?.call_recordings
      ?.map((recording) => recording?.url)
      .join("\n")}`;
    Clipboard.setString(text);
  };

  const title = `${
    isCustomerSaved || canSeeCustomerContact
      ? selected.author?.name
      : "Unknown Customer"
  }${!!hasParent ? `: ${parentName}` : ""}`;

  const onBlockPress = () => {
    history.push(
      { hash: "confirm-delete" },
      {
        message: `This will block ${title}${
          canSeeCustomerContact ? " " + phoneNumber : ""
        }. Do you want to continue?`,
        type: "block-contact",
        id: selected?.author?.id,
        onSuccessMessage: Strings.BLOCK_NUMBER_SUCCESS,
        onErrorMessage: Strings.BLOCK_NUMBER_ERROR,
        title: Strings.BLOCK_NUMBER_CONFIRM_HEADING,
      },
    );
  };

  const onUnblockContact = () => () => {
    const title =
      isCustomerSaved || canSeeCustomerContact
        ? selected.author?.name
        : "Unknown Customer";
    history.push(
      { hash: "confirm-delete" },
      {
        message: `This will unblock ${title}${
          canSeeCustomerContact ? " " + phoneNumber : ""
        }. Do you want to continue?`,
        type: "unblock-customer",
        id: selected?.author?.id,
        onSuccessMessage: Strings.UNBLOCK_NUMBER_SUCCESS,
        onErrorMessage: Strings.UNBLOCK_NUMBER_ERROR,
        title: Strings.UNBLOCK_NUMBER_CONFIRM_HEADING,
      },
    );
  };

  useEffect(() => {
    let t;
    if (copied) {
      t = setTimeout(() => {
        setCopied(() => false);
      }, 300);
    }

    return () => clearTimeout(t);
  }, [copied]);

  return (
    <>
      <Row
        center
        pl={32}
        pr={16}
        style={{
          justifyContent: "space-between",
          height: 75,
          borderBottomWidth: 1,
          borderBottomColor: palette.light_grey,
        }}
      >
        <Heading size={5} mb={0} mt={0}>
          Call Log Details
        </Heading>
        <SolidButton
          label="Back"
          color={palette.white}
          style={{
            borderWidth: 0,
          }}
          onPress={() => {
            setPanelVisible(false);
          }}
        />
      </Row>
      <View
        style={{
          paddingHorizontal: 12,
          paddingVertical: 6,
        }}
      >
        <BlockedBanner
          onUnBlockPress={onUnblockContact}
          isBlocked={isBlocked}
          isAdmin={isAdmin}
        />
        <DataField
          label={<Text style={[styles.text_label]}>Name:</Text>}
          value={
            <Text style={[styles.texting]}>
              {isCustomerSaved || canSeeCustomerContact
                ? selected.author?.name
                : "Unknown Customer"}
              {!!hasParent && `: ${parentName}`}
            </Text>
          }
        />
        <DataField
          label={<Text style={[styles.text_label]}>Number:</Text>}
          value={
            <Row leftCenter>
              {canSeeCustomerContact && (
                <Text style={[styles.texting]}>{phoneNumber}</Text>
              )}
              <View
                style={{
                  marginLeft: 8,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Row
                  center
                  style={{
                    gap: 8,
                  }}
                  mt={4}
                >
                  <IconButton
                    faPro
                    faProIcon={faPhone}
                    iconColor={palette.success}
                    onPress={onCallPress}
                    iconSize={14}
                    width={18}
                    height={18}
                    ml={0}
                    mr={0}
                    disabled={!activeNumber}
                  />
                  <IconButton
                    faPro
                    faProIcon={faComments}
                    iconColor={palette.primary}
                    disabled={!activeNumber}
                    onPress={() => {
                      setSelected(null);
                      setPanelVisible(false);
                    }}
                    iconSize={14}
                    width={18}
                    height={18}
                    ml={0}
                    mr={0}
                  />
                  <IconButton
                    faPro
                    faProIcon={selected?.reported ? faFlagSolid : faFlag}
                    iconColor={palette.info}
                    onPress={onReportPress}
                    iconSize={14}
                    width={18}
                    height={18}
                    ml={0}
                    mr={0}
                  />
                  {!isBlocked && (
                    <IconButton
                      faPro
                      faProIcon={faBan}
                      onPress={onBlockPress}
                      iconColor={palette.blocked}
                      iconSize={14}
                      width={18}
                      height={18}
                      ml={0}
                      mr={0}
                    />
                  )}
                </Row>
              </View>
            </Row>
          }
        />
        <DataField
          label={<Text style={[styles.text_label]}>Call Info:</Text>}
          value={
            <Text style={[styles.texting]}>
              {callStatus} Call on {getSmartTimestamp(selected.created_at)}
            </Text>
          }
        />
        <DataField
          label={<Text style={[styles.text_label]}>{`Phone Line Used`}:</Text>}
          value={
            <Text style={[styles.texting]}>
              {selected?.twilio_number?.friendly_name || "-"}
              {" - "}
              {phoneLineUsed}
            </Text>
          }
        />
        <DataField
          label={<Text style={[styles.text_label]}>Lead Source:</Text>}
          value={
            <Text style={[styles.texting]}>
              {selected.twilio_number?.lead_source_name || "Not Assigned"}
            </Text>
          }
        />
        {selected?.meta?.voicemail && selected?.meta?.voicemail !== null && (
          <DataField
            label={
              <Text style={[styles.text_label]}>Voicemail Recording:</Text>
            }
            value={
              <View>
                <ReactAudioPlayer
                  src={selected?.meta?.voicemail?.url}
                  controls
                  controlsList="nodownload noplaybackrate"
                  style={{
                    height: "50px",
                    borderRadius: "6px",
                  }}
                />
              </View>
            }
          />
        )}
        {selected?.meta?.call_recordings?.length > 0 && (
          <DataField
            label={<Text style={[styles.text_label]}>Call Recordings:</Text>}
            value={
              <>
                {hasMultipleCallRecordings && (
                  <Row mb={8}>
                    <SolidButton
                      faIcon={copied ? faCheck : faLink}
                      label={copied ? "Copied" : "Copy All"}
                      iconPosition="right"
                      onPress={onCopyAllRecordingsPress}
                      color={palette.success}
                    />
                  </Row>
                )}
                {selected?.meta?.call_recordings?.map((recording) => (
                  <Row
                    center
                    style={{ justifyContent: "flex-start", gap: 8 }}
                    mb={8}
                  >
                    <ReactAudioPlayer
                      key={recording?.id}
                      src={recording?.url}
                      controls
                      controlsList="nodownload noplaybackrate"
                      style={{
                        height: "50px",
                        borderRadius: "6px",
                      }}
                    />
                    <CopyButton text={recording?.url} icon={faLink} />
                  </Row>
                ))}
              </>
            }
          />
        )}
        {!selected?.missed && (
          <DataField
            label={<Text style={[styles.text_label]}>Call Duration:</Text>}
            value={
              <Text style={[styles.texting]}>
                {!selected.missed &&
                  getCommaSeparatedHoursMinutesSeconds(selected.meta.duration)}
              </Text>
            }
          />
        )}
        <CallNotes />
      </View>
    </>
  );
};

const CallNotes = () => {
  const { selected, setSelected, updateCall } = useContext(CallsContext);
  const [callNote, setCallNote] = useState(selected?.note || "");
  const [saving, setSaving] = useState(false);

  const saveNote = async () => {
    setSaving(true);
    const res = await Engage.updateCallNote({
      id: selected?.id,
      note: callNote,
    });
    if (!res?.error) {
      setSelected(() => {
        updateCall(res?.response);
        return res?.response;
      });
    }
    setSaving(false);
  };

  useEffect(() => {
    setCallNote(selected?.note || "");
  }, [selected]);

  return (
    <View>
      <DataField
        label={<Text style={[styles.text_label]}>Call Notes:</Text>}
        value={
          <View>
            <StyledInput
              multiline
              containerStyle={{
                width: "90%",
                borderWidth: StyleSheet.hairlineWidth,
                borderColor: palette.light_grey,
              }}
              numberOfLines={5}
              value={callNote}
              onChangeText={setCallNote}
              nativeID="call_note"
            />
            {callNote !== selected?.note && (
              <Row mt={8}>
                <SolidButton
                  label="Save Note"
                  loading={saving}
                  onPress={saveNote}
                  style={{ minWidth: 100 }}
                  disabled={!callNote || !callNote?.trim()}
                />
              </Row>
            )}
          </View>
        }
      />
    </View>
  );
};

export const DataField = ({ label, value }) => {
  return (
    <Row
      center
      pt={12}
      pb={12}
      style={{
        gap: 30,
        alignItems: "center",
      }}
    >
      <Col
        right
        maxWidth={200}
        size={4}
        style={{ justifyContent: "flex-start" }}
      >
        {label}
      </Col>
      <Col size={8} style={{ justifyContent: "flex-start" }}>
        {value}
      </Col>
    </Row>
  );
};

const CustomerCreatePanel = () => {
  const { participant } = useContext(CallsContext);

  return (
    <ContactProvider value={{ ...participant }}>
      <CustomerCreateForm />
    </ContactProvider>
  );
};

const CustomerCreateForm = () => {
  const { contact, createContact, dirty, saving } = useContext(ContactContext);
  const {
    setParticipant,
    setCustomerFormVisible,
    refreshCallLogs,
    setSelected,
  } = useContext(CallsContext);

  const onCreate = useCallback(async () => {
    await createContact();
    setParticipant({});
    setCustomerFormVisible(false);
    setSelected({});
    await refreshCallLogs();
  }, [createContact, history]);

  return (
    <ScrollView
      style={{ flex: 1, width: "100%" }}
      contentContainerStyle={{ padding: 16 }}
    >
      <Row style={{ justifyContent: "space-between" }}>
        <Heading>Create Customer</Heading>
        <SolidButton
          label="Cancel"
          onPress={() => {
            setParticipant({});
            setCustomerFormVisible(false);
          }}
        />
      </Row>
      <CustomerForm type="create" modal={false} />
      <SolidButton
        label="Create Customer"
        onPress={onCreate}
        disabled={!contact.first_name || !contact.phone || !dirty}
        color={palette.success}
        loading={saving}
      />
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  greeting: {
    textAlign: "center",
    fontSize: 30,
  },
  text: {
    textAlign: "center",
    fontSize: 18,
    color: palette.grey,
  },
  listPanel: {
    borderRightWidth: 1,
    borderRightColor: palette.light_grey,
    height: "100%",
  },
  rightPanel: {},
  centerPanel: {
    borderRightWidth: 1,
    borderRightColor: palette.light_grey,
    justifyContent: "center",
    alignItems: "center",
    alignContent: "center",
    minWidth: 380,
    // height: "100%",
  },
  container: {
    flex: 1,
  },
  inner: {
    flex: 1,
  },
  link: {
    color: palette.primary_light,
    fontWeight: "600",
    fontFamily: "OpenSans_600SemiBold",
    fontSize: 18,
  },
  texting: {
    fontWeight: "600",
    fontFamily: "OpenSans_600SemiBold",
    fontSize: 16,
  },
  text_label: {
    color: palette.label_grey,
    fontSize: 16,
  },
});
