import { Strings } from "./strings";

export const ADD_WARNING_ACTION_TYPE = "ADD_WARNING";
export const CLEAR_WARNING_ACTION_TYPE = "CLEAR_WARNING";
export const RESET_ACTION_TYPE = "RESET";
export const HIDE_WARNING_ACTION_TYPE = "HIDE_WARNING";
export const HIDE_ALL_WARNING_ACTION_TYPE = "HIDE_ALL_WARNING";

export const INIT_WARNINGS_STATE = {
  warnings: [],
  hiddenWarnings: {},
};

export const CALL_QUALITY_WARNING_ACTION_TYPES = {
  ADD_WARNING: ADD_WARNING_ACTION_TYPE,
  CLEAR_WARNING: CLEAR_WARNING_ACTION_TYPE,
  RESET: RESET_ACTION_TYPE,
  HIDE_WARNING: HIDE_WARNING_ACTION_TYPE,
  HIDE_ALL_WARNING: HIDE_ALL_WARNING_ACTION_TYPE,
};

export const CALL_QUALITY_WARNING_HIGH_RTT = "high-rtt";
export const CALL_QUALITY_WARNING_LOW_MOS = "low-mos";
export const CALL_QUALITY_WARNING_HIGH_JITTER = "high-jitter";
export const CALL_QUALITY_WARNING_HIGH_PACKET_LOSS = "high-packet-loss";
export const CALL_QUALITY_WARNING_HIGH_PACKET_LOSS_FRACTION =
  "high-packets-lost-fraction";
export const CALL_QUALITY_WARNING_LOW_BYTES_RECEIVED = "low-bytes-received";
export const CALL_QUALITY_WARNING_LOW_BYTES_SENT = "low-bytes-sent";
export const CALL_QUALITY_WARNING_ICE_CONNECTIVITY_LOST =
  "ice-connectivity-lost";

export const CALL_QUALITY_WARNING_TYPES = {
  [CALL_QUALITY_WARNING_HIGH_RTT]: {
    title: Strings.CALL_QUALITY_WARNING_HIGH_RTT_TITLE,
    message: Strings.CALL_QUALITY_WARNING_HIGH_RTT_MESSAGE,
  },
  [CALL_QUALITY_WARNING_LOW_MOS]: {
    title: Strings.CALL_QUALITY_WARNING_LOW_MOS_TITLE,
    message: Strings.CALL_QUALITY_WARNING_LOW_MOS_MESSAGE,
  },
  [CALL_QUALITY_WARNING_HIGH_JITTER]: {
    title: Strings.CALL_QUALITY_WARNING_HIGH_JITTER_TITLE,
    message: Strings.CALL_QUALITY_WARNING_HIGH_JITTER_MESSAGE,
  },
  [CALL_QUALITY_WARNING_HIGH_PACKET_LOSS]: {
    title: Strings.CALL_QUALITY_WARNING_HIGH_PACKET_LOSS_TITLE,
    message: Strings.CALL_QUALITY_WARNING_HIGH_PACKET_LOSS_MESSAGE,
  },
  [CALL_QUALITY_WARNING_HIGH_PACKET_LOSS_FRACTION]: {
    title: Strings.CALL_QUALITY_WARNING_HIGH_PACKET_LOSS_TITLE,
    message: Strings.CALL_QUALITY_WARNING_HIGH_PACKET_LOSS_MESSAGE,
  },
  [CALL_QUALITY_WARNING_LOW_BYTES_RECEIVED]: {
    title: Strings.CALL_QUALITY_WARNING_LOW_BYTES_RECEIVED_TITLE,
    message: Strings.CALL_QUALITY_WARNING_LOW_BYTES_RECEIVED_MESSAGE,
  },
  [CALL_QUALITY_WARNING_LOW_BYTES_SENT]: {
    title: Strings.CALL_QUALITY_WARNING_LOW_BYTES_SENT_TITLE,
    message: Strings.CALL_QUALITY_WARNING_LOW_BYTES_SENT_MESSAGE,
  },
  [CALL_QUALITY_WARNING_ICE_CONNECTIVITY_LOST]: {
    title: Strings.CALL_QUALITY_WARNING_ICE_CONNECTIVITY_LOST_TITLE,
    message: Strings.CALL_QUALITY_WARNING_ICE_CONNECTIVITY_LOST_MESSAGE,
  },
};
