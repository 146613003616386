export const Strings = {
  CALL_FORWARDING_LABEL: "Call Forwarding",
  PHONE_TREE_LABEL: "Phone Tree",
  SEQUENTIAL_CALL_LABEL: "Sequential Call Ring",
  SEQUENTIAL_CALL_MISCONFIGURED_NUMBER:
    "This phone line is not available for Sequential Call Ring, please remove this.",
  A2P_10DLC_SUBMISSION_MESSAGE: `We'll verify your new number for Engage within two weeks. After approval, you can start sending messages.\nCheck here or contact support for updates.`,
  FLICENT_CAMPAIGN_WARNING_MESSAGE:
    "To unlock the full potential of Engage and gain access to all features, please resubmit your 10DLC campaign with your business details. Your initial submission included FieldPulse business details, which needs to be updated.",
  RESUBMIT_CAMPAIGN_LABEL: "Resubmit Campaign",
  FLICENT_CAMPAIGN_CALLER_ID_MESSAGE:
    "Invalid 10 DLC information. Please update before gaining access to the Caller ID feature. ",
  CALLER_ID_DOCS_URL:
    "https://help.fieldpulse.com/en/articles/9390426-caller-id",
  WORKING_HOURS_DOCS_URL:
    "https://help.fieldpulse.com/en/articles/9077040-engage-working-hours",
  CALL_ROUTING_DOCS_URL:
    "https://help.fieldpulse.com/en/articles/9077045-call-routing",
  VOICEMAIL_DOCS_URL:
    "https://help.fieldpulse.com/en/articles/9077046-engage-voicemails",
  A2P_10DLC_DOCS_URL:
    "https://help.fieldpulse.com/en/articles/9077053-10dlc-information",
  REPORTING_DOCS_URL:
    "https://help.fieldpulse.com/en/articles/9829548-engage-reporting",
  BLOCKED_NUMBERS_DOCS_URL:
    "https://help.fieldpulse.com/en/articles/10098709-blocking-numbers-in-engage",
  COMMUNICATIONS: {
    CALL: "CALL",
    MESSAGE: "MESSAGE",
  },
  CALL_REPORTING_CATEGORY_NO_AUDIO_INCOMING:
    "No Audio (I could not hear the customer)",
  CALL_REPORTING_CATEGORY_NO_AUDIO_OUTGOING:
    "No Audio (Customer could not hear me)",
  CALL_REPORTING_CATEGORY_CALL_DROPPED: "Call Dropped",
  CALL_REPORTING_CATEGORY_ECHO_ON_LINE: "Echo on Line",
  CALL_REPORTING_CATEGORY_DELAYED_AUDIO: "Delayed Audio",
  CALL_REPORTING_CATEGORY_POOR_AUDIO_QUALITY: "Poor Audio Quality",
  CALL_REPORTING_CATEGORY_MIS_ROUTED: "Misrouted Calls",
  CALL_REPORTING_CATEGORY_INTERRUPTIONS: "Frequent Interruptions",
  CALL_REPORTING_CATEGORY_LOW_VOLUME: "Volume Too Low",
  CALL_REPORTING_CATEGORY_OTHER_ISSUE: "Other Issue",
  CALL_REPORTING_ISSUE_FACED_MESSAGE:
    "Sorry you're experiencing issues with the call.",
  CALL_REPORTING_ISSUE_FACED_MESSAGE_SUB_TEXT:
    "Thank you for bringing this to our attention. We're sorry to hear about the issues you're experiencing with the call. Our team will investigate the reported problem. If we identify a defect, we will take steps to resolve it. Should we need more information to proceed, we'll reach out to you directly. We appreciate your patience as we work to improve your experience.",
  CALL_REPORTING_ISSUE_NOT_FACED_MESSAGE: "Thank you for your feedback!",
  CALL_REPORTING_ISSUE_NOT_FACED_MESSAGE_SUB_TEXT:
    "We're glad to hear that the call quality was great. If you have any more feedback or need further assistance, feel free to let us know!",
  CALL_REPORTING_GENERAL_MESSAGE:
    "Enjoying the call experience? Let us know how the quality was - your feedback makes a difference!",
  CALL_REPORTING_HEADING: "We Value Your Feedback!",
  CALL_REPORTING_DESCRIPTION_REQUIRED:
    "Please provide a description of your issue. We'll use this to help our team investigate.",
  CALL_REPORTING_CATEGORY_REQUIRED: "Please select a category for your issue.",
  FIELDPULSE_SUPPORT_EMAIL: "support@fieldpulse.com",
  SIGNPOST_IFRAME_URL: "https://app.signpost.com/iframe-login",
  BLOCKED_BANNER_MESSAGE:
    "This phone number has been blocked. You are unable to send any outgoing SMS or make any outgoing calls.",
  BLOCKED_BANNER_UNBLOCK_MESSAGE: "Click here to unblock.",
  ADD_BLOCKED_NUMBER_SUBTEXT:
    "Activating number blocking will prevent any further incoming and outgoing communications, including calls and messages, from this number.",
  ADD_BLOCK_NUMBER_HEADING: "Add Number to Your Block List",
  BLOCK_NUMBER_SUCCESS: "Contact Successfully Blocked",
  BLOCK_NUMBER_ERROR: "Contact could not be blocked",
  UNBLOCK_NUMBER_SUCCESS: "Contact Successfully Unblocked",
  UNBLOCK_NUMBER_ERROR: "Contact could not be Unblocked",
  BLOCK_CONTACTS_SEARCH_PLACEHOLDER:
    "Search for a customer or contact to block, or type an unknown number",
  PLAY_RECORDING_MESSAGE_DISABLE_WARNING: {
    MESSAGE:
      "Please be aware that you are choosing not to notify your customers that their calls are being recorded.\nMany U.S. states legally require that all parties be informed when a call is being recorded.\nAre you sure you want to proceed without notification?",
    CANCEL_BTN: "No, I'll Notify",
    PROCEED_BTN: "Yes, Proceed Without Notifying",
  },
  BLOCK_NUMBER_CONFIRM_HEADING: "Confirm Number Block",
  UNBLOCK_NUMBER_CONFIRM_HEADING: "Confirm Number Unblock",
  TWO_FA: {
    GENERAL_HEADING: "Two-Factor Authentication (2FA)",
    REQUIRED_MESSAGE: "2FA login is required by your company",
    STEP_1: {
      LABEL: "STEP 1",
      MESSAGE:
        "Download an authenticator app from your device's app store. We recommend Google Authenticator, Microsoft Authenticator, or Authy.",
    },
    STEP_2: {
      LABEL: "STEP 2",
      MESSAGE:
        "In your authenticator app, click “Add” and scan the QR code below or enter the code manually.",
    },
    STEP_3: {
      LABEL: "STEP 3",
      MESSAGE:
        "Once the key has been provided in the app, enter the 6-digit verification code below.",
    },
    MANUAL_CODE_LABEL: "Manual Code",
    BACKUP_CODES: {
      GENERAL_HEADING: "Download Backup Codes",
      GENERAL_MESSAGE:
        "Download your backup codes to securely access your account if you ever lose your primary authentication device, ensuring uninterrupted login access and account security.",
      DOWNLOAD_BUTTON_LABEL: "Download Codes",
      CONTINUE_BUTTON_LABEL: "Continue",
    },
  },
  VERIFICATION_CODE: {
    LABEL: "Verification Code",
    GENERAL_MESSAGE: "Enter the verification code from the authenticator app",
    CONFIRM_BUTTON_LABEL: "Confirm",
    OPTIONAL_MESSAGE_CODE: "Want to enter a code? ",
    OPTIONAL_MESSAGE_RECOVERY_CODE: "Having problems? ",
    USE_CODE: "Use a code",
    USE_RECOVERY_CODE: "Use a recovery code",
    RECOVERY_CODE_LABEL: "Recovery Code",
    VERIFICATION_CODE_LABEL: "Verification Code",
  },
  HOLD_SETTINGS: {
    GENERAL_HEADING: "Hold Settings",
    HOLD_SETTINGS_DOCS_URL:
      "https://help.fieldpulse.com/en/articles/9981373-engage-call-hold",
    HOLD_AUDIO: {
      GENERAL_HEADING: "Hold Audio",
      GENERAL_MESSAGE:
        "This audio will be played when you place a customer on hold",
    },
  },
  AUDIO_SAMPLES: {
    NO_AUDIO_SAMPLE_MESSAGE:
      "You don't have any audio samples. You can add them ",
  },
  AUDIO_SAMPLES_HEADING: "Audio Samples",
  AUDIO_SAMPLES_DESCRIPTION:
    "Audio Sample are custom audio messages that can be used for voicemail greetings or hold music.",
  AUDIO_SAMPLE_ADD_NEW: "Add New Audio Sample",
  AUDIO_SAMPLES_EMPTY_MESSAGE: "No Audio Samples Available",
  AUDIO_SAMPLE_SUB_HEADING: "Your Audio Samples",
  CHOOSE_AUDIO_SAMPLE: "Choose Audio Sample to manage its content.",
  GENERAL_TITLE_LABEL: "Title",
  GENERAL_DESCRIPTION_LABEL: "Description",
  GENERAL_SAVE_LABEL: "Save",
  GENERAL_CANCEL_LABEL: "Cancel",
  GENERAL_DELETE_LABEL: "Delete",
  GENERAL_UPLOAD_LABEL: "Upload",
  GENERAL_EDIT_LABEL: "Edit",
  GENERAL_YES_LABEL: "Yes",
  GENERAL_TROUBLESHOOTING_GUIDE_LABEL: "Troubleshooting Guide",
  GENERAL_LEARN_MORE_LABEL: "Learn More",
  MESSAGE_NOT_DELIVERED_LABEL: "Not delivered",
  MESSAGE_TRY_AGAIN_LABEL: "Try Again",
  MESSAGE_DELETE_LABEL: "Delete",
  MESSAGE_DELETE_CONFIRM_MESSAGE:
    "Are you sure you want to delete this message?",
  A2P_NOT_VERIFIED_MESSAGE:
    "Prior to using Engage, we'll verify your new number within two weeks. You can return here after that time for an update or contact our chat support for information on your Engage number. Once approved, you can send messages immediately.",
  HAS_OPTED_OUT_MESSAGE: "is blocked as the user opted out.",
  HAS_OPTED_OUT_DOCS_URL:
    "https://help.fieldpulse.com/en/articles/10335077-sms-opt-out-fieldpulse-engage",
  RING_DURATION_HEADING: "Ring Duration",
  RING_DURATION_DOCS_URL:
    "https://help.fieldpulse.com/en/articles/10294966-ring-duration",
  RING_DURATION_GENERAL_LABEL: "General",
  RING_DURATION_GENERAL_MESSAGE:
    "Select how long you want incoming calls to ring before the caller is sent to voicemail.",
  RING_DURATION_CALL_ROUTING_LABEL: "Sequential Call Ring [Call Routing]",
  RING_DURATION_CALL_ROUTING_MESSAGE:
    "Set the ring duration for incoming calls on phone lines using sequential call routing.\nThis determines how long each line rings before moving to the next in the queue or triggering the final action, such as call forwarding.",
  CALL_QUALITY_WARNING_HIGH_RTT_TITLE: "Attention: Network Delay Detected!",
  CALL_QUALITY_WARNING_HIGH_RTT_MESSAGE:
    "We've noticed that your calls might be experiencing some audio delays. This usually happens due to a slow network connection.",
  CALL_QUALITY_WARNING_LOW_MOS_TITLE: "Alert: Low Call Quality Detected!",
  CALL_QUALITY_WARNING_LOW_MOS_MESSAGE:
    "It looks like your recent calls may have had lower quality than usual. This can be caused by network issues affecting sound clarity.",
  CALL_QUALITY_WARNING_HIGH_JITTER_TITLE:
    "Warning: Unstable Call Quality Detected!",
  CALL_QUALITY_WARNING_HIGH_JITTER_MESSAGE:
    "We've detected some instability in your call quality, which could lead to crackling or choppy audio. This often occurs due to network issues.",
  CALL_QUALITY_WARNING_HIGH_PACKET_LOSS_TITLE:
    "Caution: Potential Call Disruptions Detected!",
  CALL_QUALITY_WARNING_HIGH_PACKET_LOSS_MESSAGE:
    "Your call quality might be affected due to some data not reaching its destination. This can cause choppy audio or even dropped calls.",
  CALL_QUALITY_WARNING_LOW_BYTES_RECEIVED_TITLE: "Alert: No Data Received!",
  CALL_QUALITY_WARNING_LOW_BYTES_RECEIVED_MESSAGE:
    "It appears that your device is not receiving any data, which might interrupt your current session. This can happen if your network connection is lost.",
  CALL_QUALITY_WARNING_LOW_BYTES_SENT_TITLE:
    "Warning: Data Transmission Issue Detected!",
  CALL_QUALITY_WARNING_LOW_BYTES_SENT_MESSAGE:
    "We've noticed that no data is being sent from your device, which may cause communication problems. This typically indicates a connection or software issue.",
  CALL_QUALITY_WARNING_ICE_CONNECTIVITY_LOST_TITLE:
    "Connection Alert: Disruption Detected!",
  CALL_QUALITY_WARNING_ICE_CONNECTIVITY_LOST_MESSAGE:
    "Your current session has lost its network connection and needs to reconnect. This can affect your ongoing activities.",
  CALL_QUALITY_DEFAULT_TITLE: "Call Quality Warning",
  CALL_QUALITY_DEFAULT_MESSAGE:
    "We've noticed some issues with your call quality. Please check your network connection and try again.",
  CALL_QUALITY_WARNING_DOCS_URL:
    "https://help.fieldpulse.com/en/articles/10289678-complete-guide-to-engage-network-warning-messages",
  CALL_QUALITY_WARNINGS_CLEAR_ALL_LABEL: "Clear All Notifications",
};
