import defaultsDeep from "lodash.defaultsdeep";

export class Model {
  constructor(attributes, fields) {
    if (!fields) {
      defaultsDeep(this, attributes, this.defaults);
    } else {
      // Currently it only handles up to 2 levels
      fields.forEach((field) => {
        if (field.includes(".")) {
          const [firstKey, secondKey] = field.split(".");
          if (!this[firstKey]) {
            this[firstKey] = {};
          }
          this[firstKey][secondKey] = attributes[firstKey]?.[secondKey];
        } else {
          this[field] = attributes[field];
        }
      });
    }
  }

  get(key_name) {
    return this[key_name];
  }

  set(key_name, value) {
    this[key_name] = value;
    return this;
  }
}

export class List {
  constructor(items = []) {
    this.models = items.map((item) => new this.model(item));
  }

  get model() {
    return Model;
  }
}
