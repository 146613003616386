import {
  CALL_QUALITY_WARNING_ACTION_TYPES,
  INIT_WARNINGS_STATE,
} from "../constants/callQualityWarning";
import CallQualityWarningService from "../models/CallQualityWarningService";

export const callQualityWarningReducer = (
  state = INIT_WARNINGS_STATE,
  action,
) => {
  const { type, payload } = action;

  // console.log(`[${callQualityWarningReducer.name}]`, type, state, action);
  switch (type) {
    case CALL_QUALITY_WARNING_ACTION_TYPES.ADD_WARNING: {
      const callQualityWarnings = new CallQualityWarningService(state);
      callQualityWarnings.addWarning(payload.callSid, payload.warningName);
      return {
        ...state,
        warnings: callQualityWarnings.warnings,
      };
    }

    case CALL_QUALITY_WARNING_ACTION_TYPES.CLEAR_WARNING: {
      const callQualityWarnings = new CallQualityWarningService(state);
      callQualityWarnings.removeWarning(payload.callSid, payload.warningName);
      return {
        ...state,
        warnings: callQualityWarnings.warnings,
      };
    }

    case CALL_QUALITY_WARNING_ACTION_TYPES.HIDE_WARNING: {
      const callQualityWarnings = new CallQualityWarningService(state);
      callQualityWarnings.hideWarning(payload.warningName);
      return {
        ...state,
        hiddenWarnings: callQualityWarnings.hiddenWarnings,
      };
    }

    case CALL_QUALITY_WARNING_ACTION_TYPES.HIDE_ALL_WARNING: {
      const callQualityWarnings = new CallQualityWarningService(state);
      callQualityWarnings.hideAllWarnings();
      return {
        ...state,
        hiddenWarnings: callQualityWarnings.hiddenWarnings,
      };
    }

    case CALL_QUALITY_WARNING_ACTION_TYPES.RESET: {
      return INIT_WARNINGS_STATE;
    }

    default: {
      return state;
    }
  }
};
